// import Button from 'react-bootstrap/Button';
import React, { useState, forwardRef, useEffect } from 'react';
import { InputText } from "../../componentes/editar/InputText";
import { InputTextMonto } from "../../componentes/editar/alquiler/InputTextMonto";
import { InputTextAdelanto } from "../../componentes/editar/alquiler/InputTextAdelanto";
import { InputComboBoxBusquedaTextId } from "../../componentes/editar/InputComboBoxBusquedaTextId";
import { InputComboBoxBusquedaHuesped } from "../../componentes/agregar/InputComboBoxBusquedaHuesped";
// venta
import { InputComboBoxBusquedaItem } from "../../componentes/editar/alquiler/InputComboBoxBusquedaItem";
import { InputComboBoxFormaPago } from "../../componentes/editar/alquiler/InputComboBoxFormaPago";
// cliente
import { InputComboBoxGenero } from "../../componentes/editar/cliente/InputComboBoxGenero";
import { InputComboBoxTipoDocumento } from "../../componentes/editar/cliente/InputComboBoxTipoDocumento";
import { InputComboBoxTipoDocumentoFactura } from "../../componentes/editar/cliente/InputComboBoxTipoDocumentoFactura";
import { InputComboBoxRangoEdad } from "../../componentes/editar/cliente/InputComboBoxRangoEdad";
import { InputAreaText } from "../../componentes/editar/InputAreaText";
// import { InputComboBoxText } from "../../componentes/editar/InputComboBoxText";
import { InputComboBoxTextId } from "../../componentes/editar/InputComboBoxTextId";
import { InputComboBoxN2TextId } from "../../componentes/editar/InputComboBoxN2TextId";
import { InputComboBoxEstadoCivil } from "../../componentes/editar/cliente/InputComboBoxEstadoCivil";
import { InputComboBoxParentesco } from "../../componentes/editar/cliente/InputComboBoxParentesco";
import { InputDate } from "../../componentes/editar/InputDate";
import { InputCheckBox } from "../../componentes/editar/InputCheckBox";
// calculo
import { InputDateFechaFin } from "../../componentes/editar/alquiler/InputDateFechaFin";
import { InputTimeHoraFin } from "../../componentes/editar/alquiler/InputTimeHoraFin";
import { Link, useParams } from "react-router-dom";
import Toast from 'react-bootstrap/Toast';
import  RouterView  from '../../componentes/layout/RouterView';
import Sidebar from "../../pages/Sidebar";
import  HeadView  from "../../componentes/layout/HeadView";
import { FooterView } from '../../componentes/layout/FooterView';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import Accordion from 'react-bootstrap/Accordion';
import '../../Assets/css/din_style_2.css'
import '../../Assets/css/StyleSlidebar.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import { InputTextPrecioUnitario } from "../../componentes/editar/alquiler/InputTextPrecioUnitario";
import { InputTextCantidad } from "../../componentes/editar/alquiler/InputTextCantidad";
// import { InputComboBoxIgv } from "../../componentes/agregar/alquiler/InputComboBoxIgv";
import { InputComboBoxTributo } from "../../componentes/editar/alquiler/InputComboBoxTributo";
// factura
import { InputComboBoxComprobante } from "../../componentes/editar/factura/InputComboBoxComprobante";
import { InputComboBoxMedio } from "../../componentes/editar/factura/InputComboBoxMedio";
import { InputComboBoxIncidencia } from "../../componentes/editar/factura/InputComboBoxIncidencia";
//animacion
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';
import API_BASE_URL  from "../../config";

const nombre_controller = 'Alquileres';        // <<<<<< CAMBIO
const url = `${API_BASE_URL}`;       // <<<<<< CAMBIO
const RutaHead = [
    { name: 'Alquiler', ruta: `/${nombre_controller}List` },          // <<<<<< CAMBIO
    { name: 'Edicion Alquiler', ruta: `/${nombre_controller}Upd` },  // <<<<<< CAMBIO
];
function AlquilerUpd() {
    const { id, id2 } = useParams();  
    //Redux
    const user = useSelector((state) => state.user);
    const [Idempresa, setIdempresa] = useState(user.companyId);
    const [Idempresa2, setIdempresa2] = useState(user.companyId);
    const [Idusuario, setIdusuario] = useState(user.userId);
    // Función para actualizar el usuario en el estado local cuando cambie en Redux
    useEffect(() => {
        setIdempresa(user.companyId);
        setIdempresa2(user.companyId);
        setIdusuario(user.userId);
    }, [user]);

    //const [Idempresa] = useState(user.companyId);
    //const [Idempresa2] = useState(user.companyId);             
    //const [Idusuario] = useState(user.userId); 
    // datos de alojamiento
    const [Idalquiler, setIdalquiler] = useState('');  // <<<<<< CAMBIO
    const [Alqcod, setAlqcod] = useState('');  // <<<<<< CAMBIO
    const [Txtduracion, setTxtduracion] = useState('');  // <<<<<< CAMBIO
    const [Cantidad, setCantidad] = useState(1);  // <<<<<< CAMBIO
    const [Monto, setMonto] = useState('');  // <<<<<< CAMBIO
    const [MontoCobrar, setMontoCobrar] = useState(0.00);  // <<<<<< CAMBIO
    const [MontoTotal, setMontoTotal] = useState(0.00);  // <<<<<< CAMBIO
    const [Adelanto, setAdelanto] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckoutDate, setAlojCheckoutDate] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckoutTime, setAlojCheckoutTime] = useState('');  // <<<<<< CAMBIO
    const [Idcategoria, setIdcategoria] = useState('');  // <<<<<< CAMBIO
    // datos internos
    const [FechaInicioTemp, setFechaInicioTemp] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckinDate, setAlojCheckinDate] = useState('');  // <<<<<< CAMBIO
    const [AlojCheckinTime, setAlojCheckinTime] = useState('');  // <<<<<< CAMBIO
    const [AlojDuracion, setAlojDuracion] = useState('');  // <<<<<< CAMBIO
    // datos cliente
    const [Clinom] = useState('');                   // <<<<<< CAMBIO
    const [Cliruc, setCliruc] = useState('');                   // <<<<<< CAMBIO
    const [Txttipodocumento, setTxttipodocumento] = useState(''); // <<<<<< CAMBIO
    //datos huespedes
    const [IdclienteSearch, setIdclienteSearch] = useState('');             // <<<<<< CAMBIO
    const [Idcliente2, setIdcliente2] = useState('');             // <<<<<< CAMBIO
    const [Clinom2, setClinom2] = useState('');                   // <<<<<< CAMBIO
    const [Cliruc2, setCliruc2] = useState('');                   // <<<<<< CAMBIO
    const [Clitelf2, setClitelf2] = useState('');                 // <<<<<< CAMBIO
    const [Clidirec2, setClidirec2] = useState('');               // <<<<<< CAMBIO
    const [Cliciudad2, setCliciudad2] = useState('');             // <<<<<< CAMBIO
    const [Cliemail2, setCliemail2] = useState('');               // <<<<<< CAMBIO
    const [Cliobs2, setCliobs2] = useState('');                   // <<<<<< CAMBIO
    const [Iddepartamento2, setIddepartamento2] = useState('');   // <<<<<< CAMBIO
    const [Txtdepartamento2, setTxtdepartamento2] = useState(''); // <<<<<< CAMBIO
    const [Idprovincia2, setIdprovincia2] = useState('');         // <<<<<< CAMBIO
    const [Txtprovincia2, setTxtprovincia2] = useState('');       // <<<<<< CAMBIO
    const [Iddistrito2, setIddistrito2] = useState('');           // <<<<<< CAMBIO
    const [Txtdistrito2, setTxtdistrito2] = useState('');         // <<<<<< CAMBIO
    const [Txttipodocumento2, setTxttipodocumento2] = useState(''); // <<<<<< CAMBIO
    const [Idpais2, setIdpais2] = useState('');                   // <<<<<< CAMBIO
    const [Txtpais2, setTxtpais2] = useState('');                 // <<<<<< CAMBIO
    const [FechaNacimiento2, setFechaNacimiento2] = useState(''); // <<<<<< CAMBIO
    const [Txtgenero2, setTxtgenero2] = useState('');             // <<<<<< CAMBIO
    const [Txtrangoedad2, setTxtrangoedad2] = useState('');       // <<<<<< CAMBIO
    const [Txtestadocivil2, setTxtestadocivil2] = useState('');   // <<<<<< CAMBIO
    const [Txtparentesco2, setTxtparentesco2] = useState('');     // <<<<<< CAMBIO
    const [Estado2, setEstado2] = useState(1);                   // <<<<<< CAMBIO
    const ClearComboBox2 = () => {
        //setIddepartamento2(null)    // <<<<<< CAMBIO
        setIdprovincia2(null)    // <<<<<< CAMBIO
        setIddistrito2(null)     // <<<<<< CAMBIO
    }
    // Detalle Habitacion
    const [dataDetalle, setDataDetalle] = useState([]);
    const [loadingDetalle, setLoadingDetalle]=useState(false);
    const [DataDetalleHuespedes, setDataDetalleHuespedes]=useState([]);
    useEffect(() => {
        const DataDetalle = async ()=>{
            const token = localStorage.getItem('token-dincors');
            setLoadingDetalle(true);
            try {
                const response = await fetch(`${url}/Habitaciones/HabitacionesDetail/${id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                });
                if(response.ok){
                    const data = await response.json();
                    if(data.res){
                        setDataDetalle(data.datos);
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }finally{
                setLoadingDetalle(false);
            }
        }
        DataDetalle();
    }, [id]);
    useEffect(() => {
        const DataAlquiler = async ()=>{
            const token = localStorage.getItem('token-dincors');
            try {
                const response = await fetch(`${url}/Alquileres/AlquileresUpd/${id2}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                });
                if(response.ok){
                    const data = await response.json();
                    //console.log("datos: ",data)
                    if(data.res){
                        // Datos calculo aloj_monto
                        setTxtduracion(data.datos.txtduracion);
                        setCantidad(data.datos.cantidad_duracion);
                        setAdelanto(data.datos.adelanto);
                        setAlojCheckoutDate(data.datos.aloj_checkout_fecha);
                        setAlojCheckoutTime(data.datos.aloj_checkout_hora);
                        setMontoCobrar(data.datos.aloj_monto_cobrar ? data.datos.aloj_monto_cobrar : 0.00);
                        setMontoTotal(data.datos.aloj_monto_total ? data.datos.aloj_monto_total : 0.00);
                        // Datos alquiler
                        setAlqcod(data.datos.alqcod)
                        setAlojDuracion(data.datos.txtduracion);
                        setIdalquiler(data.datos.idalquiler);
                        setIdcategoria(data.datos.idcategoria);
                        setFechaInicioTemp(data.datos.aloj_checkin);
                        setAlojCheckinDate(data.datos.aloj_checkin_fecha);
                        setAlojCheckinTime(data.datos.aloj_checkin_hora);
                        setTxttipoincidencia(data.datos.txttipoincidente);
                        setDescripcionfactura(data.datos.incidente ? data.datos.incidente : '');
                        // Datos cliente
                        setCliruc(data.datos.huesped_documento);
                        setIscliente(data.datos.idhuesped);
                        // Datos tarifa
                        setMonto(data.datostarifa.aloj_monto);
                        setDataDetalleTarifa(data.datostarifa.aloj_dates);
                        setInputValues(data.datostarifa.aloj_dates.map(detalle => detalle.aloj_monto));
                        setInputCantidadDuracion(data.datostarifa.aloj_dates.map(detalle => parseInt(detalle.cantidad_duracion)));
                        setSelectTxtDuracion(data.datostarifa.aloj_dates.map(detalle => detalle.txtduracion));
                        setDataDetalleHuespedes(data.huesped);
                        setDataVentasItem(data.ventasitem);
                        // Datos de CHECKOUT
                        if(data.factura){
                            if(data.factura.fechavencimiento){
                                const fechaFormateada = new Date(data.factura.fechavencimiento).toISOString().split('T')[0];
                                setFechavencimiento(fechaFormateada)
                            }else{
                                const fechaActualFormateada = new Date().toISOString().split('T')[0];
                                setFechavencimiento(fechaActualFormateada);
                            }
                            setIdfactura(data.factura.id);
                            //if(data.factura.nombre === "FACTURA"){
                                const tiposDocumentos = {
                                    1: "SIN RUC",
                                    2: "DNI",
                                    3: "CARNET DE EXTRANJERIA",
                                    4: "RUC",
                                    5: "PASAPORTE",
                                    6: "CED. DIPLOMATICA DE IDENTIDAD",
                                    7: "DOC. IDENT. PAIS. RESIDENCIA-NO.D",
                                    8: "TAX IDENTIFICATION NUMBER",
                                    9: "IDENTIFICATION NUMBER",
                                };
                                console.log("tipo: ",data.factura.clitipo)
                                setTipodocfactura(tiposDocumentos[data.factura.clitipo] || 0);
                                setRucfactura(data.factura.cliruc);
                                setRazonfactura(data.factura.clinombre);
                                setDirecfactura(data.factura.clidireccion);
                            //}
                            setNotasfactura(data.factura.notas);
                            setIdmediopago(data.factura.mediopago ? data.factura.mediopago : '999');
                            setMediopagofactura(data.factura.mediopago ? data.factura.mediopago : 'Otros medio de pago');
                            setIdtipocomprobante(data.factura.tipo ? data.factura.tipo : 0);
                            setEstadoFactura(data.factura.estado);
                            // setFormapagofactura(data.factura.formapago ? data.factura.formapago : '01');
                        }else{
                            setTipodocfactura(data.datos.txttipodocumento);
                            setRucfactura(data.datos.huesped_documento);
                            setRazonfactura(data.datos.huesped_nombre);
                            setDirecfactura(data.datos.huesped_direccion);
                        }
                        //cuotas
                        // if(data.cuotas){
                        //     setDataCuotasFactura(data.cuotas);
                            // setInputFechaCuota(data.cuotas.map(detalle => detalle.fecha));
                            // setInputMontoCuota(data.cuotas.map(detalle => detalle.monto));
                        //}
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }
        DataAlquiler();
    }, [id2]);
    // ====================== MODAL DETALLE TARIFA ======================  
    const [ShowDetalleTarifa, setShowDetalleTarifa] = useState(false);
    const CloseDetalleTarifa = () => setShowDetalleTarifa(false);
    const [DataDetalleTarifa, setDataDetalleTarifa]=useState([]);
    const handleShowDetalleTarifa = () => {
        if(AlojDuracion !== '03 DIAS AUTORIZAR' && AlojDuracion !== '04 HORAS AUTORIZAR'){
            setShowDetalleTarifa(true);
        }
    };
    // Input Monto
    const [inputValues, setInputValues] = useState(DataDetalleTarifa.map(detalle => detalle.aloj_monto));
    const handleInputChange = (e, index) => {
        const newInputValues = [...inputValues];
        const inputValue = e.target.value.trim();
        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (inputValue !== "") {
                newInputValues[index] = (inputValue);
                setRecalcularTarifa(true);
            } else {
                newInputValues[index] = 0; // O cualquier otro valor predeterminado cuando el campo está vacío
            }
            //newInputValues[index] = inputValue;
        } else {
            newInputValues[index] = 0; // o cualquier otro valor predeterminado cuando el campo está vacío
        }
        setInputValues(newInputValues);
        const newDetalleTarifa = [...DataDetalleTarifa];
        newDetalleTarifa[index].aloj_monto = newInputValues[index];
        setDataDetalleTarifa(newDetalleTarifa);
        //setDataDetalleTarifa(newDetalleTarifa);
    };
    // Input Cantidad Duracion
    const [InputCantidadDuracion, setInputCantidadDuracion] = useState(DataDetalleTarifa.map(detalle => parseInt(detalle.cantidad_duracion)));
    const handleInputCantidadDuracion = (e, index) => {
        const newInputCantidadDuracion = [...InputCantidadDuracion];
        const inputValue = e.target.value.trim();
        if (/^\d*\.?\d*$/.test(inputValue)) {
            if (inputValue !== "") {
                newInputCantidadDuracion[index] = parseInt(inputValue);
                setRecalcularTarifa(true);
            } else {
                newInputCantidadDuracion[index] = 1;
            }
        } else {
            newInputCantidadDuracion[index] = 1;
        }
        setInputCantidadDuracion(newInputCantidadDuracion);
        const newDetalleTarifa = [...DataDetalleTarifa];
        newDetalleTarifa[index].cantidad_duracion = newInputCantidadDuracion[index];
        setDataDetalleTarifa(newDetalleTarifa);
    };
    // Input Monto
    const [selectFormaPago, setSelectFormaPago] = useState(DataDetalleTarifa.map(detalle => detalle.idformapago));
    const handleComboFormaPago = (e, index) => {
        const newselectFormaPago = [...selectFormaPago];
        const inputValue = e.target.value.trim();
        if (inputValue !== "") {
            newselectFormaPago[index] = parseInt(inputValue);
            setRecalcularTarifa(true);
        } else {
            newselectFormaPago[index] = 1;
        }
        setSelectFormaPago(newselectFormaPago);
        const newDetalleTarifa = [...DataDetalleTarifa];
        newDetalleTarifa[index].idformapago = newselectFormaPago[index];
        setDataDetalleTarifa(newDetalleTarifa);
    };
    // Input TxtDuracion
    const [selectTxtDuracion, setSelectTxtDuracion] = useState(DataDetalleTarifa.map(detalle => detalle.txtduracion));
    const handleComboTxtDuracion = (e, index) => {
        const newselectTxtDuracion = [...selectTxtDuracion];
        const inputValue = e.target.value.trim();
        if (inputValue !== "") {
            newselectTxtDuracion[index] = (inputValue);
            setRecalcularTarifa(true);
        } else {
            newselectTxtDuracion[index] = '03 DIA ADICIONAL';
        }
        setSelectTxtDuracion(newselectTxtDuracion);
        const newDetalleTarifa = [...DataDetalleTarifa];
        newDetalleTarifa[index].txtduracion = newselectTxtDuracion[index];
        setDataDetalleTarifa(newDetalleTarifa);
    };
    // Confirma el calculo
    const FnOkCalcularTarifa= async() => {
        // const totalTarifas = DataDetalleTarifa.reduce((sum, detalle) => sum + parseFloat(detalle.tarifa), 0);
        // const totalTarifas = DataDetalleTarifa.reduce((sum, detalle) => {
        //     const tarifaComoNumero = parseFloat(detalle.aloj_monto);
        //     return isNaN(tarifaComoNumero) ? sum : sum + tarifaComoNumero;
        // }, 0);
        // setMonto(totalTarifas);
        setAdelanto(0);
        setShowDetalleTarifa(false);
        if(RecalcularTarifa){
            FnActualizarFechaAlquiler();
            setRecalcularTarifa(false);
        }
        if(DataDetalleTarifa.some(detalle => detalle.aloj_monto === 0)){
            setToastBackground('#CC6868'); //198754 CC6868
            setToastMessage("No hay cambios realizados. Verifique su operacion");
            setShowToast(true);
        }
    };
    useEffect(() => {
        if(Iddistrito2){
            UbicarNiveles(Iddistrito2, {
                setIddepartamento2,  // <<<<<< CAMBIO
                setIdprovincia2,     // <<<<<< CAMBIO
                setIddistrito2,      // <<<<<< CAMBIO
            })
            .catch(function (error) {
            });
        }else{
            ClearComboBox2();
        }
    }, [Iddistrito2]);
    // ====================== LIMPIAR CONSTANTES ======================
    const FnLimpiarDatos2 = () => {
        setIdcliente2('');          // <<<<<< CAMBIO
        setClinom2('');          // <<<<<< CAMBIO
        setCliruc2('');          // <<<<<< CAMBIO
        setClitelf2('');         // <<<<<< CAMBIO
        setClidirec2('');        // <<<<<< CAMBIO    
        setCliciudad2('');       // <<<<<< CAMBIO    
        setCliemail2('');        // <<<<<< CAMBIO    
        setCliobs2('');          // <<<<<< CAMBIO
        setIddepartamento2('');      // <<<<<< CAMBIO    
        setTxtdepartamento2(''); // <<<<<< CAMBIO
        setIdprovincia2('');      // <<<<<< CAMBIO    
        setTxtprovincia2('');    // <<<<<< CAMBIO        
        setIddistrito2('');      // <<<<<< CAMBIO    
        setTxtdistrito2('');     // <<<<<< CAMBIO    
        setTxttipodocumento2('');// <<<<<< CAMBIO            
        setIdpais2('');          // <<<<<< CAMBIO
        setTxtpais2('');         // <<<<<< CAMBIO
        setFechaNacimiento2(''); // <<<<<< CAMBIO        
        setTxtgenero2('');       // <<<<<< CAMBIO    
        setTxtrangoedad2('');    // <<<<<< CAMBIO        
        setTxtestadocivil2('');  // <<<<<< CAMBIO        
        setTxtparentesco2('');   // <<<<<< CAMBIO        
        setEstado2(1);          // <<<<<< CAMBIO
    }
    // ====================== MODAL AGREGAR CLIENTE ======================  
    const [ShowCrear, setShowCrear] = useState(false);
    const CloseCrearModal = () => setShowCrear(false);
    const handleShowCrear = () => {
        setShowCrear(true);
    };
    // ====================== MODAL EDITAR CLIENTE ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowEditar, setShowEditar] = useState(false);
    const CloseEditarModal = () => setShowEditar(false);
    const token2 = localStorage.getItem('token-dincors');
    const handleShowEditar = (itemId) => {
        UbicarRegistroHuesped(url,itemId,"Clientes",token2, {
            setIdcliente2,
            setClinom2,          // <<<<<< CAMBIO
            setCliruc2,          // <<<<<< CAMBIO
            setClitelf2,         // <<<<<< CAMBIO
            setClidirec2,        // <<<<<< CAMBIO    
            setCliciudad2,       // <<<<<< CAMBIO    
            setCliemail2,        // <<<<<< CAMBIO    
            setCliobs2,          // <<<<<< CAMBIO
            setTxtdepartamento2, // <<<<<< CAMBIO        
            setTxtprovincia2,    // <<<<<< CAMBIO        
            setIddistrito2,      // <<<<<< CAMBIO    
            setTxtdistrito2,     // <<<<<< CAMBIO    
            setTxttipodocumento2,// <<<<<< CAMBIO            
            setIdpais2,          // <<<<<< CAMBIO
            setTxtpais2,         // <<<<<< CAMBIO
            setFechaNacimiento2, // <<<<<< CAMBIO        
            setTxtgenero2,       // <<<<<< CAMBIO    
            setTxtrangoedad2,    // <<<<<< CAMBIO        
            setTxtestadocivil2,  // <<<<<< CAMBIO        
            setTxtparentesco2,   // <<<<<< CAMBIO        
            setEstado2,          // <<<<<< CAMBIO
        })
        .then(function (data) {
            setShowEditar(true);
        })
        .catch(function (error) {
        });
    };
    const FnActualizarHuesped = async() => {
        let mensaje='';
        if(Clinom2.trim() !== '' && Cliruc2.trim() !== '' && Txttipodocumento2 !== '' 
        && Idpais2.toString().trim() !== '' && Txtgenero2 !== '' && Txtrangoedad2 !== '' 
        && Clidirec2.trim() !== ''){     // <<<<<< CAMBIO
            let cant = Cliruc2.toString().length;        // <<<<<< CAMBIO
            if(Txttipodocumento2==='RUC' && cant < 11){// <<<<<< CAMBIO
                mensaje =`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres para el cliente.`;
                setToastMessage(mensaje);
                setShowToast(true);
                setToastBackground('#CC6868');
            }else{
                const token = localStorage.getItem('token-dincors');
                const now = new Date();
                const jsonData = {
                    Idalquiler:id2,
                    Iscliente: Iscliente,
                    Idcliente: Idcliente2,      // <<<<<< CAMBIO
                    Clinom: Clinom2,     // <<<<<< CAMBIO
                    Cliruc: Cliruc2,     // <<<<<< CAMBIO
                    Clitelf: Clitelf2,   // <<<<<< CAMBIO
                    Clidirec: Clidirec2, // <<<<<< CAMBIO
                    Cliciudad:Cliciudad2,// <<<<<< CAMBIO
                    Cliemail: Cliemail2, // <<<<<< CAMBIO
                    Cliobs: Cliobs2,     // <<<<<< CAMBIO
                    Txtdepartamento: Txtdepartamento2,   // <<<<<< CAMBIO
                    Txtprovincia: Txtprovincia2, // <<<<<< CAMBIO
                    Txtdistrito: Txtdistrito2,   // <<<<<< CAMBIO
                    Iddistrito: Iddistrito2,     // <<<<<< CAMBIO
                    Txttipodocumento: Txttipodocumento2, // <<<<<< CAMBIO
                    Idpais: Idpais2,     // <<<<<< CAMBIO
                    Txtpais: Txtpais2,   // <<<<<< CAMBIO
                    Idempresa: Idempresa2, // <<<<<< CAMBIO
                    FechaNacimiento: FechaNacimiento2,   // <<<<<< CAMBIO
                    Txtgenero: Txtgenero2,   // <<<<<< CAMBIO
                    Txtrangoedad: Txtrangoedad2, // <<<<<< CAMBIO
                    Txtestadocivil: Txtestadocivil2, // <<<<<< CAMBIO
                    Txtparentesco: Txtparentesco2,   // <<<<<< CAMBIO
                    Estado: Estado2 ? 2 : 1, // <<<<<< CAMBIO
                    Fechact: now.toISOString(),
                    Codusuact: Idusuario,
                };
                try{
                    const response = await fetch(`${url}/AlquilerHuesped/AlquilerHuespedUpdate`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(jsonData),
                    })
                    if(response.ok){
                        const data = await response.json();
                        if(data.res){
                            setToastBackground('#198754');
                            setToastMessage(data.msg);
                            setShowToast(true);
                            setShowEditar(false);
                            FnLimpiarDatos2();
                            setDataDetalleHuespedes(data.datos);
                        }else{
                            throw new Error(data.msg);
                        }
                    }
                } catch(error) {
                    setToastBackground('#CC6868');
                    setToastMessage(error.message);
                    setShowToast(true);
                    setShowEditar(false);
                };
            }
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    };
    // ====================== MODAL ELIMINAR CLIENTE ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowEliminar, setShowEliminar] = useState(false);
    const CloseEliminarModal = () => setShowEliminar(false);
    const [SelectedIdEliminar, setSelectedIdEliminar] = useState(null);
    const handleShowEliminar = (itemId) => {
        setSelectedIdEliminar(itemId);
        setShowEliminar(true);
    };
    const FnEliminarModal = async () => {
        const jsonData = {
            Idcliente:SelectedIdEliminar,
            Idalquiler:id2,
            Idempresa:Idempresa,
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try{
            const response = await fetch(`${url}/AlquilerHuesped/AlquilerHuespedDelete`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            })
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    CloseEliminarModal();
                    setDataDetalleHuespedes((prevOrden) => prevOrden.filter(item => item.idcliente !== SelectedIdEliminar)); // <<<<<< CAMBIO
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch(error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Agregar Huesped
    const FnCrearHuesped = async () => {
        let mensaje='';
        if(Clinom2.trim() !== '' && Cliruc2.trim() !== '' && Txttipodocumento2 !== '' 
        && Idpais2.toString().trim() !== '' && Txtgenero2 !== '' && Txtrangoedad2 !== '' 
        && Clidirec2.trim() !== ''){     // <<<<<< CAMBIO
            let cant = Cliruc2.toString().length;        // <<<<<< CAMBIO
            if(Txttipodocumento2==='RUC' && cant < 11){// <<<<<< CAMBIO
                mensaje =`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres para el cliente.`;
                setToastMessage(mensaje);
                setShowToast(true);
                setToastBackground('#CC6868');
            }else{
                const now = new Date();
                const jsonData = {
                    Idalquiler:id2,
                    Idcliente: Idcliente2,      // <<<<<< CAMBIO
                    Clinom: Clinom2,     // <<<<<< CAMBIO
                    Cliruc: Cliruc2,     // <<<<<< CAMBIO
                    Clitelf: Clitelf2,   // <<<<<< CAMBIO
                    Clidirec: Clidirec2, // <<<<<< CAMBIO
                    Cliciudad:Cliciudad2,// <<<<<< CAMBIO
                    Cliemail: Cliemail2, // <<<<<< CAMBIO
                    Cliobs: Cliobs2,     // <<<<<< CAMBIO
                    Txtdepartamento: Txtdepartamento2,   // <<<<<< CAMBIO
                    Txtprovincia: Txtprovincia2, // <<<<<< CAMBIO
                    Txtdistrito: Txtdistrito2,   // <<<<<< CAMBIO
                    Iddistrito: Iddistrito2,     // <<<<<< CAMBIO
                    Txttipodocumento: Txttipodocumento2, // <<<<<< CAMBIO
                    Idpais: Idpais2,     // <<<<<< CAMBIO
                    Txtpais: Txtpais2,   // <<<<<< CAMBIO
                    Idempresa: Idempresa2, // <<<<<< CAMBIO
                    FechaNacimiento: FechaNacimiento2,   // <<<<<< CAMBIO
                    Txtgenero: Txtgenero2,   // <<<<<< CAMBIO
                    Txtrangoedad: Txtrangoedad2, // <<<<<< CAMBIO
                    Txtestadocivil: Txtestadocivil2, // <<<<<< CAMBIO
                    Txtparentesco: Txtparentesco2,   // <<<<<< CAMBIO
                    Estado: Estado2 ? 2 : 1, // <<<<<< CAMBIO
                    Fechreg: now.toISOString(),
                    Fechact: now.toISOString(),
                    Codusureg: Idusuario,
                    Codusuact: Idusuario,
                };
                const token = localStorage.getItem('token-dincors');
                try{
                    const response = await fetch(`${url}/AlquilerHuesped/AlquilerHuespedCreate`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify(jsonData),
                    })
                    if(response.ok){
                        const data = await response.json();
                        if(data.res){
                            setToastBackground('#198754');
                            setToastMessage(data.msg);
                            setShowToast(true);
                            setShowCrear(false);
                            setDataDetalleHuespedes(data.huesped);
                            FnLimpiarDatos2();
                        }else{
                            throw new Error(data.msg);
                        }
                    }
                } catch(error) {
                    setToastBackground('#CC6868');
                    setToastMessage(error.message);
                    setShowToast(true);
                    setShowEditar(false);
                };
            }
        }else{
            mensaje =`Operación detenida. Llene todos los campos obligatorios.`;
            setToastMessage(mensaje);
            setShowToast(true);
            setToastBackground('#CC6868');
        }
    }
    // Agregar Huesped
    const FnAgregarHuesped = async () => {
        if(IdclienteSearch!==''){
            try {
                const jsonData = {
                    Idcliente:IdclienteSearch,
                    Idalquiler:id2,
                    Idhabitacion: id,
                    CantidadPersonas: DataDetalleHuespedes.length,
                    Idempresa:Idempresa,
                    Codusureg: Idusuario,
                };
                const token = localStorage.getItem('token-dincors');
                const response = await fetch(`${url}/AlquilerHuesped/AlquilerHuespedAgregar`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){
                    const data = await response.json();
                    if(data.res){
                        setDataDetalleHuespedes(data.huesped);
                        setIdclienteSearch('')
                    }else{
                        throw new Error(data.msg);
                    }
                }else{
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }catch (error) {
                setToastBackground('#CC6868');
                setToastMessage(error.message);
                setShowToast(true);
            }
        }else{
            setToastBackground('#CC6868');
            setToastMessage("Operacion detenida. Ningun huesped seleccionado.");
            setShowToast(true);
        }
        
    };
    // Actualizar Cliente
    const [Iscliente, setIscliente] = useState('');                   // <<<<<< CAMBIO
    const FnActualizarCliente = async (idcliente) => {
        try {
            const jsonData = {
                Idcliente:idcliente,
                Idalquiler:id2,
                Idempresa:Idempresa,
                Codusuact: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/AlquilerHuesped/AlquilerHuespedActualizar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){
                const data = await response.json();
                if(data.res){
                    setIscliente(idcliente);
                    setDataDetalleHuespedes(data.huesped);
                    setTipodocfactura(data.factura.txttipodocumento);
                    setRucfactura(data.factura.huesped_documento);
                    setRazonfactura(data.factura.huesped_nombre);
                    setDirecfactura(data.factura.huesped_direccion);
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // Quitar fecha
    const [RecalcularTarifa, setRecalcularTarifa] = useState(false);
    const FnDeleteCantidadTarifa = async (index,idalquilerfecha) => {
        const newDataDetalleTarifa = DataDetalleTarifa.filter((_, i) => i !== index);
        setDataDetalleTarifa(newDataDetalleTarifa);
        //setCantidad((prevCantidad) => prevCantidad - 1);
        setRecalcularTarifa(true);
    };
    // Calculo general
    const FnActualizarFechaAlquiler = async () => {
        const jsonData = {
            Idalquiler:Idalquiler,
            Duracion: Txtduracion,
            Cantidad: Cantidad,
            Idcategoria: Idcategoria,
            FechaIngreso: FechaInicioTemp,
            AlojDates: DataDetalleTarifa,
            Idempresa: Idempresa,
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try {
            if (DataDetalleTarifa===null || (Array.isArray(DataDetalleTarifa) && DataDetalleTarifa.length === 0)) {
                throw new Error("Operacion Detenida. Debe ingresar al menos una hora o dia.");
            }
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}FechaAlquiler`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setCantidad(data.datos.aloj_cantidad);
                    setMonto(data.datos.aloj_monto);
                    setMontoCobrar(data.datos.aloj_monto_cobrar);
                    setMontoTotal(data.datos.aloj_monto_total);
                    // ========= btn-ok-calcularTarifa =========
                    // Datos calculo
                    setAlojCheckoutDate(data.datos.aloj_checkout_date);
                    setAlojCheckoutTime(data.datos.aloj_checkout_time);
                    // Datos internos
                    setFechaInicioTemp(data.datos.aloj_checkin);
                    setAlojCheckinDate(data.datos.aloj_checkin_date);
                    setAlojCheckinTime(data.datos.aloj_checkin_time);
                    setAlojDuracion(data.datos.aloj_duracion);
                    setDataDetalleTarifa(data.datos.aloj_dates);
                    setInputCantidadDuracion(data.datos.aloj_dates.map(detalle => parseInt(detalle.cantidad_duracion)));
                    setInputValues(data.datos.aloj_dates.map(detalle => detalle.aloj_monto));
                    // ========= btn-ok-calcularTarifa =========
                    //window.location.reload();
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    //setShowDetalleTarifa(false);
                }else{
                    setDataDetalleTarifa(data.datos.aloj_dates);
                    setInputCantidadDuracion(data.datos.aloj_dates.map(detalle => parseInt(detalle.cantidad_duracion)));
                    setInputValues(data.datos.aloj_dates.map(detalle => detalle.aloj_monto));
                    //setSelectTxtDuracion(data.datos.aloj_dates.map(detalle => detalle.txtduracion));
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }

    };
    // Agregar alojamiento adicional
    const FnAgregarAlojamientoAdicional = async () => {
        // Obtener la última fecha y sumar un día
        const ultimaFecha = DataDetalleTarifa.reduce((maxFecha, detalle) => {
            const fechaActual = new Date(detalle.aloj_fecha);
            return fechaActual > maxFecha ? fechaActual : maxFecha;
        }, new Date());
        // Sumar un día a la última fecha
        ultimaFecha.setDate(ultimaFecha.getDate() + 1);
        // Formatear la nueva fecha como una cadena legible
        const nuevaFechaFormateada = ultimaFecha.toISOString().split('T')[0];
        // Crear una nueva fila con valores predeterminados
        const nuevaFila = {
            aloj_fecha: nuevaFechaFormateada,
            aloj_monto: 0,
            cantidad_duracion: 1,
            idalquiler: id2,
            idalquilerfecha: null,
            idcajaapertura: null,
            idempresa: Idempresa,
            idestadopago: 2,
            idformapago: 1,
            txtduracion: '03 DIA ADICIONAL',
            txtestadopago: 'PENDIENTE',
            txtformapago: 'EFECTIVO',
            // codusureg: 1,
            // codusuact: 1,
        };
        // Actualizar el estado DataDetalleTarifa
        setDataDetalleTarifa((prevData) => [...prevData, nuevaFila]);
        // setInputCantidadDuracion[index] = 1;
        setInputCantidadDuracion((prevInput) => [...prevInput, 1]);
        setInputValues((prevInput) => [...prevInput, 0]);

    };
    // Check de confirmacion de pago
    const handleCheckPago = async (value,idestadopago, index) => {
        //const newCheckPago = [...CheckPago];
        try {
            const jsonData = {
                Alqcod: Alqcod,
                tipopago: 'SERVICIO',
                Idalquilerfecha:value,
                Idalquiler:id2,
                Idhabitacion: id,
                Txthabitacion : dataDetalle.numero,
                Txtcategoria: dataDetalle.categoria,
                Clinom: Clinom,
                Cliruc: Cliruc,
                Txttipodocumento: Txttipodocumento,
                Idestadopago:idestadopago,
                Idempresa:Idempresa,
                Codusuact: Idusuario,
                Codusureg: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}PagoUpdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){
                const data = await response.json();
                //console.log('datapago: ',data)
                if(data.res){
                    setMontoCobrar(data.totales.aloj_monto_cobrar);
                    setMontoTotal(data.totales.aloj_monto_total);
                    setDataDetalleTarifa(data.datos);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
        // if (inputValue !== "") {
        //     newCheckPago[index] = parseInt(inputValue);
        //     setRecalcularTarifa(true);
        // }
        //setCheckPago(newCheckPago);
        //const newDetalleTarifa = [...DataDetalleTarifa];
        //newDetalleTarifa[index].idestadopago = newCheckPago[index];
    };
    // Check de confirmacion de pago
    const handleCheckPagoProducto = async (value,idestadopago, index) => {
        try {
            const jsonData = {
                Alqcod: Alqcod,
                tipopago: 'PRODUCTO',
                Idalquilerfecha:value,
                Idalquiler:id2,
                Idhabitacion: id,
                Txthabitacion : dataDetalle.numero,
                Txtcategoria: dataDetalle.categoria,
                Clinom: Clinom,
                Cliruc: Cliruc,
                Txttipodocumento: Txttipodocumento,
                Idestadopago:idestadopago,
                Idempresa:Idempresa,
                Codusuact: Idusuario,
                Codusureg: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}PagoItemUpdate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){
                const data = await response.json();
                //console.log('datapago: ',data)
                if(data.res){
                    setMontoCobrar(data.totales.aloj_monto_cobrar);
                    setMontoTotal(data.totales.aloj_monto_total);
                    setDataVentasItem(data.datos);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                }else{
                    throw new Error(data.msg);
                }
            }else{
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error) {
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // IMPRIMIR GUIA
    // const [loadingSearch, setLoadingSearch] = useState(false);
    const handleImprimirPago = async (idfechalquiler, index) => {
        const jsonData = {
            Idalquiler: id2,
            Idalquilerfecha: idfechalquiler,
            Idempresa: Idempresa,
            Idusuario: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        //console.log("datpres: ", jsonData)
        try {
            //setLoadingSearch(true);
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}ImprimirGuia`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                //console.log("Guia: ",data) //respuesta del json de facturacion
                if(data.res){
                    const response2 = await fetch(`http://localhost:8080/ImprimirGuia`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                        },
                        body: JSON.stringify(data.datos),
                    });
                    if(response2.ok){
                        setToastBackground('#198754');
                        setToastMessage(data.msg);
                        setShowToast(true);
                    }else{
                        throw new Error('Operacion Detenida. Error al conectar con la impresion.');

                    }
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
        // finally{
        //     setTimeout(() => {
        //         setLoadingSearch(false);
        //     }, 1000);   
        // }
    };
    // ====================== CONSTANTES VENTA ======================
    const [Idfactura, setIdfactura] = useState('');             // <<<<<< CAMBIO
    const [Iditem, setIditem] = useState('');             // <<<<<< CAMBIO
    const [Itemcodigo, setItemcodigo] = useState('');             // <<<<<< CAMBIO
    const [Itemdesc, setItemdesc] = useState('');             // <<<<<< CAMBIO
    const [PrecioUnitario, setPrecioUnitario] = useState('');             // <<<<<< CAMBIO
    const [CantidadItem, setCantidadItem] = useState(1);             // <<<<<< CAMBIO
    const [TributoCodigo, setTributoCodigo] = useState('1000');             // <<<<<< CAMBIO
    const [Idformapago, setIdformapago] = useState('1');             // <<<<<< CAMBIO
    const [Txtformapago, setTxtformapago] = useState('EFECTIVO');             // <<<<<< CAMBIO
    const [Idestadopago, setIdestadopago] = useState('1');             // <<<<<< CAMBIO
    const [DataVentasItem, setDataVentasItem]=useState([]);
    const [TotalVenta, setTotalVenta] = useState(0);
    // Venta total
    useEffect(() => {
        //console.log(DataVentasItem)
        // if(DataVentasItem){
        //     const initialTotal = DataVentasItem.reduce((acc, data) => acc + data.aloj_monto * data.cantidad_duracion, 0);
        //     setTotalVenta(initialTotal);
        // }else{
        //     setTotalVenta(0.00);
        // }
        if (DataVentasItem && DataVentasItem.length > 0) {
            const initialTotal = DataVentasItem.reduce((acc, data) => acc + data.aloj_monto * data.cantidad_duracion, 0);
            setTotalVenta(initialTotal);
        } else {
            setTotalVenta(0.00);
        }
    }, [DataVentasItem]);
    // ====================== MODAL AGREGAR VENTA ======================
    const [ShowAgregarVenta, setShowAgregarVenta] = useState(false);
    const CloseVentaModal = () => setShowAgregarVenta(false);
    const handleShowAgregarVenta = () => {
        setShowAgregarVenta(true);
    };
    // Guardar la venta
    const FnAgregarVenta = async () => {
        let mensaje='';
        try{
            if(Iditem !== null ){ 
                const now = new Date();
                const jsonData = {
                    Habitacion: dataDetalle.numero,
                    Alqcod: Alqcod,
                    Idalquiler: Idalquiler,
                    Iditem: Iditem,
                    Itemcodigo: Itemcodigo,
                    Itemdesc: Itemdesc,
                    PrecioUnitario: PrecioUnitario,
                    CantidadItem: CantidadItem,
                    TributoCodigo: TributoCodigo,
                    Idformapago: Idformapago,
                    Txtformapago: Txtformapago,
                    Idestadopago: Idestadopago,
                    Idempresa: Idempresa,
                    Fechreg: now.toISOString(),
                    Fechact: now.toISOString(),
                    Codusureg: Idusuario,
                    Codusuact: Idusuario,
                };
                const token = localStorage.getItem('token-dincors');
                const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}AgregarVenta`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(jsonData),
                });
                if(response.ok){                
                    const data = await response.json();
                    //console.log("dataventa: ", data)
                    if(data.res){
                        setMontoCobrar(data.totales.aloj_monto_cobrar);
                        setMontoTotal(data.totales.aloj_monto_total);
                        setToastBackground('#198754');
                        setToastMessage(data.msg);
                        setShowToast(true);
                        setDataVentasItem(data.datos);
                        setShowAgregarVenta(false);
                        setIditem('');
                        setItemcodigo('');
                        setItemdesc('');
                        setPrecioUnitario('');
                        setCantidadItem(1);
                        setTributoCodigo('1000');
                        setIdformapago('1');
                        setTxtformapago('EFECTIVO');
                        setIdestadopago('1');
                    }else{
                        throw new Error(data.msg);
                    }
                }else  {
                    throw new Error(`${response.status} ${response.statusText}`);
                }
            }else{
                mensaje =`Operación detenida. Llene los campos de la venta.`;
                throw new Error(mensaje);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // ====================== MODAL ELIMINAR VENTA ======================
    // Agrega un estado para controlar la visibilidad del modal
    const [ShowRetirarVenta, setShowRetirarVenta] = useState(false);
    const CloseRetirarVentaModal = () => setShowRetirarVenta(false);
    // const handleShow = () => setShow(true);
    const [IdItemVenta, setIdItemVenta] = useState(null);
    const handleShowRetirarVentaModal = (itemId) => {
        setIdItemVenta(itemId);
        setShowRetirarVenta(true);
    };
    // Retirar la venta
    const FnRetirarVenta = async () => {
        try {
            const jsonData = {
                Idalquiler: id2,
                Idalquilerfecha: IdItemVenta,
                Idempresa: Idempresa,
                Codusuact: Idusuario,
            };
            const token = localStorage.getItem('token-dincors');
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}RetirarVenta`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                if(data.res){
                    setMontoCobrar(data.totales.aloj_monto_cobrar);
                    setMontoTotal(data.totales.aloj_monto_total);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setDataVentasItem((prevOrden) => prevOrden.filter(item => item.idalquilerfecha !== IdItemVenta)); // <<<<<< CAMBIO
                    // setDataVentasItem(data.datos);
                    setShowRetirarVenta(false);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
        
    };
    // ====================== MODAL PROCESAR CHECKOUT ======================
    const obtenerFechaHoraActual = () => {
        const ahora = new Date(); // Obtiene la fecha y hora actual
        const year = ahora.getFullYear();
        const month = String(ahora.getMonth() + 1).padStart(2, '0');
        const day = String(ahora.getDate()).padStart(2, '0');
        //const hours = String(ahora.getHours()).padStart(2, '0');
        //const minutes = String(ahora.getMinutes()).padStart(2, '0');
        const fechaHoraFormateada = `${year}-${month}-${day}`;
        return fechaHoraFormateada;
    };
    const [Idtipocomprobante, setIdtipocomprobante] = useState('');             // <<<<<< CAMBIO
    const [Txttipocomprobante, setTxttipocomprobante] = useState('');             // <<<<<< CAMBIO
    const [Tipodocfactura, setTipodocfactura] = useState('');             // <<<<<< CAMBIO
    const [Rucfactura, setRucfactura] = useState('');             // <<<<<< CAMBIO
    const [Razonfactura, setRazonfactura] = useState('');             // <<<<<< CAMBIO
    const [Direcfactura, setDirecfactura] = useState('');             // <<<<<< CAMBIO
    const [Fechavencimiento, setFechavencimiento] = useState(obtenerFechaHoraActual());             // <<<<<< CAMBIO
    const [Notasfactura, setNotasfactura] = useState('');             // <<<<<< CAMBIO
    const [Idmediopago, setIdmediopago] = useState('999');             // <<<<<< CAMBIO
    const [Mediopagofactura, setMediopagofactura] = useState('Otros medio de pago');             // <<<<<< CAMBIO
    const [Formapagofactura] = useState('Contado');             // <<<<<< CAMBIO     setFormapagofactura
    const [Txttipoincidencia, setTxttipoincidencia] = useState('');             // <<<<<< CAMBIO
    const [Descripcionfactura, setDescripcionfactura] = useState('');             // <<<<<< CAMBIO
    const [EstadoFactura, setEstadoFactura] = useState(''); 
    // Buscar Ruc en Sunat
    const handleInputRucFacturaChange = (e) => {
        const textValue = e.target.value;
        setRucfactura(textValue)
    };
    const handleSearchRucFactura = async () => {
        console.log('buscando...')
    };
    // Buscar Razon social en base datos
    const handleInputRazonFacturaChange = (e) => {
        const textValue = e.target.value;
        setRazonfactura(textValue)
    };
    const handleSearchRazonFactura = async () => {
        console.log('buscando2...')
    };
    // Modal
    const [ShowCheckoutModal, setShowCheckoutModal] = useState(false);
    const CloseCheckoutModal = () => setShowCheckoutModal(false);
    const handleShowCheckoutModal = () => {
        setShowCheckoutModal(true);
    };
    // ====================== PROCESAR CHECKOUT ======================
    const FnCheckoutAlquiler = async () => {
        setShowCheckoutModal(false);
        const now = new Date();
        const jsonData = {
            // Habitacion
            Idalquiler:Idalquiler,
            Idhabitacion: id,
            Idcategoria: dataDetalle.idcategoria,
            Idfactura: Idfactura,
            Cantidad: Cantidad,
            Monto: Monto,
            TotalVenta: TotalVenta,
            Txthabitacion : dataDetalle.numero,
            Txtcategoria: dataDetalle.categoria,
            // Pago
            Tipodocfactura: Tipodocfactura,
            Rucfactura: Rucfactura,
            Razonfactura: Razonfactura,
            Direcfactura: Direcfactura,
            Txttipodocumento: Txttipodocumento,
            Idmediopago: Idmediopago,
            Mediopagofactura: Mediopagofactura,
            Idtipocomprobante: Idtipocomprobante,
            Txttipocomprobante: Txttipocomprobante,
            Formapagofactura: Formapagofactura,
            // DataCuotasFactura: null,
            Fechavencimiento: Fechavencimiento,
            Notas: Notasfactura,
            // Incidente
            Txttipoincidencia: Txttipoincidencia,
            Descripcionfactura: Descripcionfactura,
            Idempresa: Idempresa,
            Fechreg: now.toISOString(),
            Fechact: now.toISOString(),
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        console.log('jsondat: ',jsonData)
        const token = localStorage.getItem('token-dincors');
        let cant = Rucfactura.toString().length;
        try {
            if(Tipodocfactura==='RUC' && cant !== 11){throw new Error(`Operación detenida. El RUC presenta ${cant} carácteres, debe tener 11 carácteres.`);}
            if(Tipodocfactura==='DNI' && cant !== 8){throw new Error(`Operación detenida. El DNI presenta ${cant} carácteres, debe tener 8 carácteres.`);}
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}CheckoutAlquiler`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                //console.log('checkout: ',data.reporte)
                if(data.res){
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setIdfactura(data.datos.id)
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // ====================== ANULAR CHECKOUT ======================
    const FnAnularCheckout = async () => {
        const now = new Date();
        const jsonData = {
            // Habitacion
            Idalquiler:Idalquiler,
            Idhabitacion: id,
            Idcategoria: dataDetalle.idcategoria,
            Idfactura: Idfactura,
            Idempresa: Idempresa,
            Fechreg: now.toISOString(),
            Fechact: now.toISOString(),
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}AnularCheckout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                //console.log(data)
                if(data.res){
                    setShowCheckoutModal(false);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    setIdfactura(null);
                    setTxttipodocumento('');
                    setIdmediopago('999');
                    setMediopagofactura('Otros medio de pago');
                    setIdtipocomprobante('');
                    setTxttipocomprobante('');
                    //setFormapagofactura('01');
                    // setDataCuotasFactura([]);
                    // setInputFechaCuota([]);
                    // setInputMontoCuota([]);
                    setFechavencimiento(obtenerFechaHoraActual());
                    setNotasfactura('');
                    // Incidente
                    setTxttipoincidencia('');
                    setDescripcionfactura('');
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }
    };
    // ====================== CIERRE ALQUILER ======================
    const [loadingCierreAlquiler, setloadingCierreAlquiler] = useState(false);
    const FnCierreAlquiler = async () => {
        setShowCheckoutModal(false);
        setloadingCierreAlquiler(true);
        const jsonData = {
            // Habitacion
            Idalquiler:Idalquiler,
            Idhabitacion: id,
            Idcategoria: dataDetalle.idcategoria,
            Idfactura: Idfactura,
            // Incidente
            Txttipoincidencia: Txttipoincidencia,
            Descripcionfactura: Descripcionfactura,
            Idempresa: Idempresa,
            Codusureg: Idusuario,
            Codusuact: Idusuario,
        };
        //console.log("cierredata: ",jsonData)
        const token = localStorage.getItem('token-dincors');
        try {
            const response = await fetch(`${url}/${nombre_controller}/${nombre_controller}CierreAlquiler`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(jsonData),
            });
            if(response.ok){                
                const data = await response.json();
                console.log("cierre: ",data)
                //console.log("presentar: ", data) //respuesta del json de facturacion
                if(data.res){
                    setDataDetalle(data.habitacion);
                    setEstadoFactura(data.estado);
                    setToastBackground('#198754');
                    setToastMessage(data.msg);
                    setShowToast(true);
                    // let rutaImprimir='ImprimirFactura'
                    // if(Txttipocomprobante==='TICKET'){
                    //     rutaImprimir='ImprimirVenta'
                    // }else{
                    //     rutaImprimir='ImprimirFactura'
                    // }
                    // //console.log('ruta: ',data.reporte)
                    // const response2 = await fetch(`http://localhost:8080/${rutaImprimir}`, {
                    //     method: 'POST',
                    //     headers: {
                    //         'Content-Type': 'application/json; charset=UTF-8',
                    //     },
                    //     body: JSON.stringify(data.reporte),
                    // });
                    // if(response2.ok){
                    //     setToastBackground('#198754');
                    //     setToastMessage(data.msg);
                    //     setShowToast(true);
                    // }else{
                    //     setToastBackground('#198754');
                    //     setToastMessage(data.msg,' - Dirijase al modulo de comprobantes para su impresion.');
                    //     setShowToast(true);
                    // } 
                    
                    // setToastBackground('#198754');
                    // setToastMessage(data.msg);
                    // setShowToast(true);
                    // setDataDetalle(data.habitacion);
                    // setEstadoFactura(data.estado);
                }else{
                    throw new Error(data.msg);
                }
            }else  {
                throw new Error(`${response.status} ${response.statusText}`);
            }
        }catch (error){
            setToastBackground('#CC6868');
            setToastMessage(error.message);
            setShowToast(true);
        }finally{
            setloadingCierreAlquiler(false);
        }
    };
    // ======== CUOTAS ========
    // const [DataCuotasFactura, setDataCuotasFactura]=useState([]);
    // Input Fecha Cuota
    // const [InputFechaCuota, setInputFechaCuota] = useState(DataCuotasFactura.map(data => data.fecha));
    // const handleInputFechaCuota = (e, index) => {
    //     const newInputFechaCuota = [...InputFechaCuota];
    //     const inputValue = e.target.value;
    //     // if (/^\d*\.?\d*$/.test(inputValue)) {
    //         if (inputValue !== "") {
    //             newInputFechaCuota[index] = inputValue;
    //             //setRecalcularTarifa(true);
    //         } else {
    //             newInputFechaCuota[index] = null;
    //         }
    //     // } else {
    //     //     newInputFechaCuota[index] = 1;
    //     // }
    //     setInputFechaCuota(newInputFechaCuota);
    //     const newDataCuotasFactura = [...DataCuotasFactura];
    //     newDataCuotasFactura[index].fecha = newInputFechaCuota[index];
    //     setDataCuotasFactura(newDataCuotasFactura);
    // };
    // Input Monto Cuota
    // const [InputMontoCuota, setInputMontoCuota] = useState(DataCuotasFactura.map(data => data.monto));
    // const handleInputMontoCuota = (e, index) => {
    //     const newInputMontoCuota = [...InputMontoCuota];
    //     const inputValue = e.target.value.trim();
    //     if (/^\d*\.?\d*$/.test(inputValue)) {
    //         if (inputValue !== "") {
    //             newInputMontoCuota[index] = (inputValue);
    //             //setRecalcularTarifa(true);
    //         } else {
    //             newInputMontoCuota[index] = 1; //poner monto
    //         }
    //     } else {
    //         newInputMontoCuota[index] = 1;  //poner monto
    //     }
    //     setInputMontoCuota(newInputMontoCuota);
    //     const newDataCuotasFactura = [...DataCuotasFactura];
    //     newDataCuotasFactura[index].monto = newInputMontoCuota[index];
    //     setDataCuotasFactura(newDataCuotasFactura);
    // };
     // Quitar fecha
    //const [RecalcularTarifa, setRecalcularTarifa] = useState(false);
    // const FnDeleteCuotaFactura = async (index) => {
    //     const newDataCuotasFactura = DataCuotasFactura.filter((_, i) => i !== index);
    //     setDataCuotasFactura(newDataCuotasFactura);
    //     const newInputFechaCuota = InputFechaCuota.filter((_, i) => i !== index);
    //     setInputFechaCuota(newInputFechaCuota);
    //     const newInputMontoCuota = InputMontoCuota.filter((_, i) => i !== index);
    //     setInputMontoCuota(newInputMontoCuota);
    // };
    // Agregar cuota adicional
    // const FnAgregarCuotaAdicional = async () => {
    //     const nuevaFila = {
    //         idfactura: Idfactura,
    //         fecha: null,
    //         monto: 0,
    //         usuario: 1,
    //     };
    //     // Actualizar el estado DataCuotasFactura
    //     setDataCuotasFactura((prevData) => [...prevData, nuevaFila]);
    //     // setInputCantidadDuracion[index] = 1;
    //     //setInputFechaCuota((prevInput) => [...prevInput, null]);
    //     //setInputMontoCuota((prevInput) => [...prevInput, 0]);
    // };
    //TOAST
    const [showToast, setShowToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastBackground , setToastBackground ] = useState('#198754');
    //Localstorage
    useEffect(() => {
        const storedMessage = localStorage.getItem('toastMessage2');
        const storedBackground = localStorage.getItem('toastBackground2');
        if (storedMessage && storedBackground) {
          setToastMessage(storedMessage);
          setToastBackground(storedBackground);
          setShowToast(true);
          localStorage.removeItem('toastMessage2');
          localStorage.removeItem('toastBackground2');
        }
    }, []);
    //Animacion al cargar
    const { opacity } = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        config: { duration: 200 },
    });
    return (
        <>
            <HeadView/>
            <Sidebar />{/* style={{ opacity: opacity }} */}
            <animated.div className="home-section" style={{opacity: opacity}}  >
                <RouterView RutaHead={RutaHead}></RouterView>
                {/* PRIMERA FILA */}
                <div className="row m-1">
                    {/* DETALLE HABITACION */}
                    <div className="col-12 col-sm-12 col-md-7 col-lg-8 mt-3">
                        <div className="card">
                            <h5 className="card-header din-card-header">DATOS DE LA HABITACION</h5>
                            <div className="card-body ">
                                {/* DETALLE */}
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 p-2">
                                        {/* Datos */}
                                        <div className="row">
                                            <div className="col-12">
                                                {loadingDetalle ? (
                                                    <p><i className="fa-solid fa-spinner fa-spin-pulse fa-lg"></i>&nbsp;&nbsp;Cargando datos..</p>
                                                ) : (
                                                    <div className="row px-4">
                                                        <div className='col-12 col-sm-12 col-md-6'>
                                                            <div className='row'>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Numero de Alquiler:&nbsp;&nbsp;</label>{Alqcod}
                                                                </div>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Numero de Habitacion:&nbsp;&nbsp;</label>{dataDetalle.numero}
                                                                </div>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Tipo de Habitacion:&nbsp;&nbsp;</label>{dataDetalle.categoria}
                                                                </div>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Estado de Habitacion:&nbsp;&nbsp;</label>
                                                                    <div className={`texto-small badge ${{1: 'bg-success',2: 'bg-danger',3: 'bg-info',}[dataDetalle.idestado]}`}>
                                                                        {dataDetalle.estado}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12 col-sm-12 col-md-6'>
                                                            <div className='row'>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Nivel:&nbsp;&nbsp;</label>{dataDetalle.nivel}
                                                                </div>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Detalle:&nbsp;&nbsp;</label>{dataDetalle.detalle}
                                                                </div>
                                                                <div className={`col-12 col-sm-12 col-md-12 mb-1`}>
                                                                    <label className="form-label fw-bold">Cantidad de personas permitidas:&nbsp;&nbsp;</label>{dataDetalle.cantidad_personas}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>     
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DETALLE TOTALES */}
                    <div className="col-12 col-sm-12 col-md-5 col-lg-4 mt-3">
                        <div className="card ">
                            <h5 className="card-header din-card-header">COBRANZA</h5>
                            <div className="card-body">
                                <div className='d-flex justify-content-center mt-2 mb-2'>
                                    <table className='mx-5 w-100'>
                                        <tbody>
                                            <tr>
                                                <td className="p-0 text-center" >PAGADO</td>
                                                <td className="p-0 text-center">TOTAL</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2 text-center texto-xl text-success" style={{borderRight:'1px solid black', fontWeight:'500'}}>{(parseFloat(MontoTotal - MontoCobrar).toFixed(2))}</td>
                                                <td className="p-2 text-center texto-xl" style={{fontWeight:'500'}}>{parseFloat(MontoTotal).toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="p-2 text-end">POR COBRAR</td>
                                                <td className="p-2 text-start texto-grande text-danger" style={{fontWeight:'500'}}>{parseFloat(MontoCobrar).toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
                {/* SEGUNDA FILA */}
                <div className="row">
                    <div className="col-12">
                        <div className="card m-3">
                            <h5 className="card-header din-card-header">DATOS DE ALQUILER</h5>
                            <div className="card-body mx-2">
                                {/* <Tabs id="controlled-tab-example" defaultActiveKey="Detalles" className="mb-0" >
                                    <Tab eventKey="Detalles" title="Detalles"> */}
                                        {/* CARD BODY */}
                                        <div className="row">
                                            {/* CLIENTE */}
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-4 pt-2">
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Datos de Huespedes</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className='row'>
                                                    <InputComboBoxBusquedaHuesped campo="" name="Idcliente" obligatorio="" idempresa={Idempresa} colsm="6" colmd="6" url={url} 
                                                        onChange={(selectedValue) => {setIdclienteSearch(selectedValue);}}/>
                                                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                                                        <Link onClick={FnAgregarHuesped} className="col-12 col-sm-6 col-md-6 mb-2">
                                                            <button className="btn btn-outline-success din-btn-style w-100 h-100">
                                                                <i className="fa-solid fa-plus"></i>&nbsp;Agregar
                                                            </button>
                                                        </Link>
                                                    </div>
                                                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                                                        <button onClick={() => handleShowCrear()} className="btn btn-outline-success din-btn-style w-100 h-100">
                                                            <i className="fa-solid fa-plus"></i>&nbsp;Nuevo
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                {DataDetalleHuespedes.map((data, index) => (
                                                    <div className="col-12 mb-3" key={index}>
                                                        <Card border={data.idcliente === Iscliente ? "success" : undefined} >
                                                            <Card.Header className={`p-0 ${data.idcliente === Iscliente ? 'bg-success' : ''}`}>
                                                                <Link>
                                                                    <button className="btn w-100" onClick={() => FnActualizarCliente(data.idcliente)}>
                                                                        <h1 className={`m-0 ${data.idcliente === Iscliente ? 'text-white' : ''}`}>
                                                                            #{index+1}: CLIENTE {data.idcliente === Iscliente ? 'PRINCIPAL' : ''}
                                                                        </h1>
                                                                    </button>
                                                                </Link>
                                                            </Card.Header>
                                                            <Card.Body>
                                                                <div className='row'>
                                                                    <div className='col-10'>
                                                                        <div className='row'>
                                                                            <div className='col-12'>
                                                                                {data.txttipodocumento}: {data.cliruc}  -  {data.clinom}
                                                                            </div>
                                                                            <div className='col-12'>
                                                                                Direccion: {data.clidirec}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-2 text-end'>
                                                                        <div className='ms-auto'><MyDropdown tabla="Huespedes" id={data.idcliente} handleShowEditar={handleShowEditar} handleShowEliminar={handleShowEliminar}/></div>
                                                                    </div>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </div>
                                                ))}
                                                </div>
                                            </div>
                                            {/* SEGUNDA COLUMNA */}
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 p-4 pt-2">
                                                {/* CALCULO */}
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Alojamiento</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <div className={`col-12 col-sm-12 col-md-12 mb-2`}>
                                                                <div className='row'>
                                                                    <Link className="col-12 col-sm-6 col-md-6 mb-2">
                                                                        <button className="btn btn-outline-success din-btn-style w-100" onClick={() => handleShowDetalleTarifa()}><i className="fa-solid fa-eye"></i>&nbsp;&nbsp;Detalle</button>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <InputTextMonto value={Monto} campo="Monto" name="Monto" obligatorio="*" colsm="6" colmd="6" onChange={setMonto}/>        {/* <<<<<< CAMBIO */}
                                                            <InputTextAdelanto value={Adelanto} campo="Adelanto" name="Adelanto" obligatorio="*" colsm="6" colmd="6" onChange={setAdelanto}/>        {/* <<<<<< CAMBIO */}
                                                            <InputDateFechaFin value={AlojCheckoutDate} campo="Fecha Fin" name="FechaFin" obligatorio="*" colsm="6" colmd="6" onChange={setAlojCheckoutDate} disabled="disabled"/>         {/* <<<<<< CAMBIO */}
                                                            <InputTimeHoraFin value={AlojCheckoutTime} campo="Hora Fin" name="HoraFin" obligatorio="*" colsm="6" colmd="6" onChange={setAlojCheckoutTime} disabled="disabled"/>         {/* <<<<<< CAMBIO */}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* SERVICIOS */}
                                                {/* Titulo */}
                                                <div className="row mt-2">
                                                    <div className="col-12">
                                                        <h5 className="card-title text-success">Servicios a la Habitacion</h5>
                                                        <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                                    </div>
                                                </div>
                                                {/* Datos */}
                                                <div className='row'>
                                                    <div className='col-12 col-sm-6 col-md-6 mb-3'>
                                                        <button onClick={() => handleShowAgregarVenta()} className="btn btn-outline-success din-btn-style w-100 h-100">
                                                            <i className="fa-solid fa-plus"></i>&nbsp;Producto
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="row" style={{padding: "0px 10.5px"}}>
                                                    {DataVentasItem.map((data, index) => (
                                                        <div className={`col-12 border-bottom border-start border-end ${index === 0 ? 'border-top' : ''} p-2`} key={index}>
                                                            <div className='row'>
                                                                <div className='col-10 col-sm-10 col-md-10'>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <span style={{fontWeight:"500"}}>{data.txtduracion}</span>
                                                                            &nbsp;|&nbsp;<span className={`text-black texto-mediano`}>{data.txtformapago}</span>
                                                                        </div>
                                                                        <div className='col-12 texto-lg'>
                                                                            <span className='texto-lg' style={{ fontWeight: '600' }}>{data.cantidad_duracion}</span> {data.mediso}&nbsp;&nbsp;
                                                                            <Link onClick={() => handleCheckPagoProducto(data.idalquilerfecha, data.idestadopago === 1 ? 2 : 1,index)}>
                                                                                <span className={`texto-xs badge ${{1: 'bg-success',2: 'bg-danger',}[data.idestadopago]}`}>{data.txtestadopago}</span>
                                                                            </Link>
                                                                            &nbsp;&nbsp;<span className={`text-black texto-xs badge bg-warning`}>{data.idcajaapertura}</span>
                                                                            &nbsp;&nbsp;<span className={`text-black texto-mediano`}>
                                                                                {{'1000': 'OPERACION GRAVADO',
                                                                                '9996': 'OPERACION GRATUITO',
                                                                                '9997': 'OPERACION EXONERADA',
                                                                                '9998': 'OPERACION INAFECTA',
                                                                                }[data.tributo_codigo]}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-2 col-sm-2 col-md-2'>
                                                                    <div className='row '>
                                                                        <div className='col-12 text-end'>
                                                                            <Link className='text-danger' onClick={() => handleShowRetirarVentaModal(data.idalquilerfecha)}>
                                                                                <i className="fas fa-trash fa-lg"></i>
                                                                            </Link>
                                                                        </div>
                                                                        <div className='col-12 text-end texto-lg' style={{ fontWeight: '600' }}>
                                                                            {parseFloat(data.aloj_monto).toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className='row mt-2' style={{ marginRight:'1px' }}>
                                                    <div className='col-10 text-end' style={{ fontWeight: '600' }}>
                                                        Total
                                                    </div>
                                                    <div className='col-2 text-end' style={{ fontWeight: '600'}}>
                                                        {TotalVenta.toFixed(2)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* </Tab>
                                </Tabs> */}
                            </div>
                            <div className="card-footer">
                                {/* Botones */}
                                <div className="row">
                                    <div className="col-12">
                                        <div className='row p-1 d-flex justify-content-end'>
                                            <Link to={`/${nombre_controller}List`} className="col-12 col-sm-4 col-md-4 mb-2">
                                                <button className="btn btn-secondary din-btn-style w-100"><i className="fa-solid fa-arrow-right-from-bracket"></i>&nbsp;&nbsp;Ir a Recepcion</button>
                                            </Link>
                                           
                                            <Link onClick={() => handleShowCheckoutModal()} className="col-12 col-sm-4 col-md-4 mb-2">
                                                <button className="btn btn-success din-btn-style w-100"><i className="fa-solid fa-check"></i>&nbsp;&nbsp;Checkout</button>
                                            </Link>
                                        </div>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Modal Detalle Tarifa */}
                <>
                    <Modal show={ShowDetalleTarifa} onHide={CloseDetalleTarifa} size="lg">
                        <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                            <Modal.Title>Alojamiento</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <table className="table table-sm table-striped table-hover table-bordered">
                                        <thead><tr>
                                            <th>Fecha</th><th>Tipo</th><th>Cantidad</th>
                                            <th>Monto</th><th>Forma</th><th>Pago</th>
                                            <th></th>
                                            <th></th>
                                        </tr></thead>
                                        <tbody>
                                            {DataDetalleTarifa.map((detalle, index) => (
                                                <tr key={index}>
                                                    {detalle.idestadopago === 2 ? (
                                                        <>
                                                            <td className='text-center' style={{verticalAlign: 'middle', width:'20%'}}>{detalle.aloj_fecha}</td>
                                                            <td style={{width:'20%'}}>
                                                                {detalle.idalquilerfecha ? (
                                                                    <select className="form-select" id={`cbxTxtDuracion`} onChange={(e) => handleComboTxtDuracion(e, index)} value={detalle.txtduracion || ""} disabled>
                                                                        <option value="01 DIAS">01 DIAS</option>
                                                                        <option value="02 HORAS">02 HORAS</option>
                                                                        <option value="03 DIA ADICIONAL">03 DIA ADICIONAL</option>
                                                                        <option value="04 HORA ADICIONAL">04 HORA ADICIONAL</option>
                                                                    </select>
                                                                ) : (
                                                                    <select className="form-select" id={`cbxTxtDuracion`} onChange={(e) => handleComboTxtDuracion(e, index)} value={detalle.txtduracion || ""}>
                                                                        <option value="01 DIAS">01 DIAS</option>
                                                                        <option value="02 HORAS">02 HORAS</option>
                                                                        <option value="03 DIA ADICIONAL">03 DIA ADICIONAL</option>
                                                                        <option value="04 HORA ADICIONAL">04 HORA ADICIONAL</option>
                                                                    </select>
                                                                )}
                                                            </td>
                                                            <td style={{width:'10%'}}>
                                                                {detalle.txtduracion === "01 DIAS" || detalle.txtduracion === "03 DIA ADICIONAL" ? (
                                                                    <input type='text' className="form-control" name={`in_txtduracion${index + 1}`}
                                                                        id={`in_txtduracion`} value={InputCantidadDuracion[index]} onChange={(e) => handleInputCantidadDuracion(e, index)} readOnly={true}/>
                                                                ) : (
                                                                    <input type='text' className="form-control" name={`in_txtduracion${index + 1}`}
                                                                        id={`in_txtduracion`} value={InputCantidadDuracion[index]} onChange={(e) => handleInputCantidadDuracion(e, index)}/>
                                                                )}
                                                            </td>
                                                            <td style={{width:'15%'}}>
                                                                <input type='text' className="form-control" name={`in_tarifa${index + 1}`}
                                                                    id={`in_tarifa`} value={inputValues[index]} onChange={(e) => handleInputChange(e, index)}/>
                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                                <select className="form-select" id={`cbxIdformapago`} onChange={(e) => handleComboFormaPago(e, index)} value={detalle.idformapago || ""}>
                                                                    <option value="1">EFECTIVO</option>
                                                                    <option value="2">TARJETA</option>
                                                                </select>
                                                            </td>
                                                            <td className='text-center' style={{verticalAlign: 'middle', width:'5%'}}>
                                                                <Link onClick={() => handleCheckPago(detalle.idalquilerfecha,1,index)}>
                                                                    <i className="fa-solid fa-square-xmark fa-lg text-secondary"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'5%' }}>
                                                                {index === DataDetalleTarifa.length - 1 && index > 0 ? (
                                                                    <Link onClick={() => FnDeleteCantidadTarifa(index,detalle.idalquilerfecha)}>
                                                                        <i className="fa-solid fa-trash text-danger"></i>
                                                                    </Link>
                                                                ):(
                                                                    <span><i className="fa-solid fa-trash text-secondary"></i></span>
                                                                )}
                                                            </td>
                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'5%' }}>
                                                                <span>
                                                                    <i className="fa-solid fa-print text-secondary"></i>
                                                                </span>
                                                            </td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <td className='text-center' style={{verticalAlign: 'middle', width:'20%'}}>{detalle.aloj_fecha}</td>
                                                            <td style={{width:'20%'}}>
                                                                <select className="form-select" id={`cbxTxtDuracion`} onChange={(e) => handleComboTxtDuracion(e, index)} value={detalle.txtduracion || ""} disabled>
                                                                    <option value="01 DIAS">01 DIAS</option>
                                                                    <option value="02 HORAS">02 HORAS</option>
                                                                    <option value="03 DIA ADICIONAL">03 DIA ADICIONAL</option>
                                                                    <option value="04 HORA ADICIONAL">04 HORA ADICIONAL</option>
                                                                </select>
                                                            </td>
                                                            <td style={{width:'10%'}}>
                                                                <input type='text' className="form-control" name={`in_txtduracion${index + 1}`}
                                                                        id={`in_txtduracion`} value={InputCantidadDuracion[index]} onChange={(e) => handleInputCantidadDuracion(e, index)} disabled/>
                                                            </td>
                                                            <td style={{width:'15%'}}>
                                                                <input type='text' className="form-control" name={`in_tarifa${index + 1}`}
                                                                    id={`in_tarifa`} value={inputValues[index]} onChange={(e) => handleInputChange(e, index)} disabled/>
                                                            </td>
                                                            <td style={{width:'20%'}}>
                                                                <select className="form-select" id={`cbxIdformapago`} onChange={(e) => handleComboFormaPago(e, index)} value={detalle.idformapago || ""} disabled>
                                                                    <option value="1">EFECTIVO</option>
                                                                    <option value="2">TARJETA</option>
                                                                </select>    
                                                            </td>
                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'5%'}}>
                                                                <Link onClick={() => handleCheckPago(detalle.idalquilerfecha,2,index)}>
                                                                    <i className="fa-solid fa-square-check fa-lg text-success"></i>
                                                                </Link>
                                                                {/* <button type="button" className="btn btn-success" id={`i_cxb_loadpago${detalle.idalquilerfecha}`} disabled>Pagado</button> */}
                                                            </td>
                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'5%' }}>
                                                                <span>
                                                                    <i className="fa-solid fa-trash text-secondary"></i>
                                                                </span>
                                                            </td>
                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'5%' }}>
                                                                <Link onClick={() => handleImprimirPago(detalle.idalquilerfecha,index)}>
                                                                    <i className="fa-solid fa-print text-success"></i>
                                                                </Link>
                                                                {/* {loadingSearch ? (
                                                                    <span>
                                                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
                                                                    </span>
                                                                ) : (
                                                                    <Link onClick={() => handleImprimirPago(detalle.idalquilerfecha,index)}>
                                                                        <i className="fa-solid fa-print text-success"></i>
                                                                    </Link>
                                                                )} */}
                                                            </td>
                                                        </>
                                                    )}
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan={5} className='text-center'>
                                                </td>
                                                <td colSpan={3} className='text-center p-0'>
                                                    <Link onClick={() => FnAgregarAlojamientoAdicional()} className='btn btn-success w-100'>
                                                        <i className="fa-solid fa-plus fa-lg text-white"></i>
                                                    </Link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    {/* <hr className="border-2 border-success" /> */}
                                    <ul style={{listStyle:'none'}} className="p-0 mb-0">
                                        <li><b>Costo del Servicio: </b>{Monto}</li>
                                        <li><b>Fecha Inicio: </b> {AlojCheckinDate} {AlojCheckinTime}</li>
                                        <li><b>Fecha Fin: </b> {AlojCheckoutDate} {AlojCheckoutTime}</li>
                                    </ul>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="success" onClick={FnOkCalcularTarifa}>Aceptar</Button>
                            <Button variant="secondary" onClick={CloseDetalleTarifa}>Cerrar</Button>
                        </Modal.Footer>
                    </Modal>
                </>
                {/* ========================== CLIENTE ========================== */}
                {/* Modal Agregar */}
                <>
                    <Modal show={ShowCrear} onHide={CloseCrearModal} size="xl">
                    <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                        <Modal.Title>Nuevo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Tabs id="controlled-tab-example" defaultActiveKey="Datos Generales" className="mb-0" >
                            <Tab eventKey="Datos Generales" title="Datos Generales">
                                <div className="row p-2">
                                    <InputText value={Clinom2} campo="Nombre/Razon Social" name="Clinom" obligatorio="*" colsm="6" colmd="4" onChange={setClinom2}/>  {/* <<<<<< CAMBIO */}
                                    <InputComboBoxTipoDocumento value={Txttipodocumento2} campo="Tipo de Documento" name="Txttipodocumento" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxttipodocumento2}/> {/* <<<<<< CAMBIO */}
                                    <InputText value={Cliruc2} campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="4" onChange={setCliruc2}/>  {/* <<<<<< CAMBIO */}
                                    <InputText value={Clitelf2} campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="4" onChange={setClitelf2}/>           {/* <<<<<< CAMBIO */}
                                    <InputComboBoxGenero value={Txtgenero2} campo="Genero" name="Txtgenero" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtgenero2}/> {/* <<<<<< CAMBIO */}
                                    <InputComboBoxRangoEdad value={Txtrangoedad2} campo="Rango de Edad" name="Txtrangoedad" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtrangoedad2}/> {/* <<<<<< CAMBIO */}
                                    <InputComboBoxBusquedaTextId value={Idpais2} campo="Pais" name="Idpais" obligatorio="*" colsm="6" colmd="4" url={url} 
                                    onChange={(selectedValue, selectedText) => {setIdpais2(selectedValue);setTxtpais2(selectedText);}}/>      {/* <<<<<< CAMBIO */}
                                    <InputComboBoxTextId value={Iddepartamento2} campo="Departamento" name="Iddepartamento" obligatorio="" colsm="6" colmd="4" url={url} 
                                    onChange={(selectedValue, selectedText) => {setIddepartamento2(selectedValue);setTxtdepartamento2(selectedText);ClearComboBox2();}} />  {/* <<<<<< CAMBIO */}
                                    <InputComboBoxN2TextId value={Idprovincia2} campo="Provincia" name="Idprovincia" obligatorio="" colsm="6" colmd="4" url={url} 
                                    onChange={(selectedValue, selectedText) => {setIdprovincia2(selectedValue);setTxtprovincia2(selectedText);}} idnivel={Iddepartamento2}/>{/* <<<<<< CAMBIO */}
                                    <InputComboBoxN2TextId value={Iddistrito2} campo="Distrito" name="Iddistrito" obligatorio="" colsm="6" colmd="4" url={url} 
                                    onChange={(selectedValue, selectedText) => {setIddistrito2(selectedValue);setTxtdistrito2(selectedText);}} idnivel={Idprovincia2}/>    {/* <<<<<< CAMBIO */}
                                    <InputText value={Cliciudad2} campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="4" onChange={setCliciudad2}/>{/* <<<<<< CAMBIO */}
                                    <InputAreaText value={Clidirec2} campo="Direccion" name="Clidirec" obligatorio="*" colsm="12" onChange={setClidirec2}/> 
                                </div>
                            </Tab>
                            <Tab eventKey="Datos Complementarios" title="Datos Complementarios">
                                <div className="row p-2">
                                    <InputDate value={FechaNacimiento2} campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="4" onChange={setFechaNacimiento2}/>
                                    <InputComboBoxEstadoCivil value={Txtestadocivil2} campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtestadocivil2}/>
                                    <InputComboBoxParentesco value={Txtparentesco2} campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtparentesco2}/>
                                    <InputText value={Cliemail2} campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="4" onChange={setCliemail2}/>
                                    <InputCheckBox value={Estado2} campo="" name="Estado" obligatorio="" colsm="6" colmd="4" mensaje="¿Cliente activo?" onChange={setEstado2}/>
                                    <InputAreaText value={Cliobs2} campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs2}/>
                                </div>
                            </Tab>
                        </Tabs>
                    </Modal.Body>
                    <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                        <div className='row mt-1'>
                            <div className="col-12 col-sm-6 col-md-3 p-1">
                                <Button variant="secondary" onClick={CloseCrearModal} className='w-100'>Cerrar</Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 p-1">
                                <Button variant="success" onClick={() => {FnCrearHuesped();}} className='w-100'>Crear</Button>
                            </div>
                        </div>
                    </Modal.Footer>
                    </Modal>
                </>
                {/* Modal Editar */}
                <>
                    <Modal show={ShowEditar} onHide={CloseEditarModal} size="xl">
                        <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                            <Modal.Title>Editar</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Tabs id="controlled-tab-example" defaultActiveKey="Datos Generales" className="mb-0" >
                                <Tab eventKey="Datos Generales" title="Datos Generales">
                                    <div className="row p-2">
                                        <InputText value={Clinom2} campo="Nombre/Razon Social" name="Clinom" obligatorio="*" colsm="6" colmd="4" onChange={setClinom2}/>  {/* <<<<<< CAMBIO */}
                                        <InputComboBoxTipoDocumento value={Txttipodocumento2} campo="Tipo de Documento" name="Txttipodocumento" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxttipodocumento2}/> {/* <<<<<< CAMBIO */}
                                        <InputText value={Cliruc2} campo="Numero de documento" name="Cliruc" obligatorio="*" colsm="6" colmd="4" onChange={setCliruc2}/>  {/* <<<<<< CAMBIO */}
                                        <InputText value={Clitelf2} campo="Telefono" name="Clitelf" obligatorio="" colsm="6" colmd="4" onChange={setClitelf2}/>           {/* <<<<<< CAMBIO */}
                                        <InputComboBoxGenero value={Txtgenero2} campo="Genero" name="Txtgenero" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtgenero2}/> {/* <<<<<< CAMBIO */}
                                        <InputComboBoxRangoEdad value={Txtrangoedad2} campo="Rango de Edad" name="Txtrangoedad" obligatorio="*" colsm="6" colmd="4" url={url} onChange={setTxtrangoedad2}/> {/* <<<<<< CAMBIO */}
                                        <InputComboBoxBusquedaTextId value={Idpais2} campo="Pais" name="Idpais" obligatorio="*" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdpais2(selectedValue);setTxtpais2(selectedText);}}/>      {/* <<<<<< CAMBIO */}
                                        <InputComboBoxTextId value={Iddepartamento2} campo="Departamento" name="Iddepartamento" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIddepartamento2(selectedValue);setTxtdepartamento2(selectedText);ClearComboBox2();}} />  {/* <<<<<< CAMBIO */}
                                        <InputComboBoxN2TextId value={Idprovincia2} campo="Provincia" name="Idprovincia" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIdprovincia2(selectedValue);setTxtprovincia2(selectedText);}} idnivel={Iddepartamento2}/>{/* <<<<<< CAMBIO */}
                                        <InputComboBoxN2TextId value={Iddistrito2} campo="Distrito" name="Iddistrito" obligatorio="" colsm="6" colmd="4" url={url} 
                                        onChange={(selectedValue, selectedText) => {setIddistrito2(selectedValue);setTxtdistrito2(selectedText);}} idnivel={Idprovincia2}/>    {/* <<<<<< CAMBIO */}
                                        <InputText value={Cliciudad2} campo="Ciudad/Referencia" name="Cliciudad" obligatorio="" colsm="6" colmd="4" onChange={setCliciudad2}/>{/* <<<<<< CAMBIO */}
                                        <InputAreaText value={Clidirec2} campo="Direccion" name="Clidirec" obligatorio="*" colsm="12" onChange={setClidirec2}/> 
                                    </div>
                                </Tab>
                                <Tab eventKey="Datos Complementarios" title="Datos Complementarios">
                                    <div className="row p-2">
                                        <InputDate value={FechaNacimiento2} campo="Fecha de Nacimiento" name="FechaNacimiento" obligatorio="" colsm="6" colmd="4" onChange={setFechaNacimiento2}/>
                                        <InputComboBoxEstadoCivil value={Txtestadocivil2} campo="Estado Civil" name="Txtestadocivil" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtestadocivil2}/>
                                        <InputComboBoxParentesco value={Txtparentesco2} campo="Parentesco" name="Txtparentesco" obligatorio="" colsm="6" colmd="4" url={url} onChange={setTxtparentesco2}/>
                                        <InputText value={Cliemail2} campo="Correo Electronico" name="Cliemail" obligatorio="" colsm="6" colmd="4" onChange={setCliemail2}/>
                                        <InputCheckBox value={Estado2} campo="" name="Estado" obligatorio="" colsm="6" colmd="4" mensaje="¿Cliente activo?" onChange={setEstado2}/>
                                        <InputAreaText value={Cliobs2} campo="Observaciones" name="Cliobs" obligatorio="" colsm="12" onChange={setCliobs2}/>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Modal.Body>
                        <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                            <div className='row mt-1'>
                                <div className="col-12 col-sm-6 col-md-3 p-1">
                                    <Button variant="secondary" onClick={() => {CloseEditarModal();}} className='w-100'>Cerrar</Button>{/*setShowAlert(false);*/}
                                </div>
                                <div className="col-12 col-sm-6 col-md-3 p-1">
                                    <Button variant="success" onClick={() => {FnActualizarHuesped();}} className='w-100'>Guardar</Button>{/*setShowAlert(false);*/}
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </>
                {/* Modal Eliminar*/}
                <>
                    <Modal show={ShowEliminar} onHide={CloseEliminarModal}>
                    <Modal.Header closeButton style={{backgroundColor:'#dc3545', color:'White'}}>
                        <Modal.Title>Retirar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea retirar este huesped del alquiler?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={CloseEliminarModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnEliminarModal}>
                            Retirar
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </>
                {/* ========================== VENTA ========================== */}
                {/* Modal Agregar */}
                <>
                    <Modal show={ShowAgregarVenta} onHide={CloseVentaModal} size="lg" >
                    <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                        <Modal.Title>Agregar venta</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row p-2'>
                            <InputComboBoxBusquedaItem campo="" name="Iditem" obligatorio="" idempresa={Idempresa} colsm="12" colmd="12" url={url} 
                            onChange={(selectedValue, selectedCodigo, selectedText) => {setIditem(selectedValue);setItemcodigo(selectedCodigo);setItemdesc(selectedText);}}/>
                            <InputTextPrecioUnitario campo="Precio Unitario" name="PrecioUnitario" obligatorio="*" colsm="6" colmd="6" onChange={setPrecioUnitario}/>        {/* <<<<<< CAMBIO */}
                            <InputTextCantidad campo="Cantidad" name="Cantidad" obligatorio="*" colsm="12" colmd="6" onChange={setCantidadItem}/>
                            <InputComboBoxTributo value={TributoCodigo} campo="Tipo Operacion" name="TributoCodigo" obligatorio="*" colsm="12" colmd="12" onChange={setTributoCodigo}/>  {/* <<<<<< CAMBIO */}
                            <InputComboBoxFormaPago value={Idformapago} campo="Forma pago" name="Idformapago" obligatorio="*" colsm="12" colmd="6" 
                                                onChange={(IdValue, TextValue) => {setIdformapago(IdValue);setTxtformapago(TextValue);}}/>
                            <div className={`col-12 col-sm-12 col-md-6 mb-3`}>
                                <label htmlFor={`cbxEstadopago`} className="form-label">Estado de pago<span className='text-danger'>*</span></label>
                                <div className="btn-group w-100" role="group" aria-label="Basic radio toggle button group">
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" 
                                    value="1" onChange={(e) => {setIdestadopago(e.target.value);}} checked={Idestadopago === '1'}/>
                                    <label className="btn btn-outline-success" htmlFor="btnradio1">PAGADO</label>
                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                                    value="2" onChange={(e) => {setIdestadopago(e.target.value);}} checked={Idestadopago === '2'}/>
                                    <label className="btn btn-outline-danger" htmlFor="btnradio2">PENDIENTE</label>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                        <div className='row mt-1'>
                            <div className="col-12 col-sm-6 col-md-3 p-1">
                                <Button variant="secondary" onClick={CloseVentaModal} className='w-100'>Cerrar</Button>
                            </div>
                            <div className="col-12 col-sm-6 col-md-3 p-1">
                                <Button variant="success" onClick={() => {FnAgregarVenta();}} className='w-100'>Agregar</Button>
                            </div>
                        </div>
                    </Modal.Footer>
                    </Modal>
                </>
                {/* Modal Eliminar*/}
                <>
                    <Modal show={ShowRetirarVenta} onHide={CloseRetirarVentaModal}>
                    <Modal.Header closeButton style={{backgroundColor:'#dc3545', color:'White'}}>
                        <Modal.Title>Retirar</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        ¿Desea retirar esta venta del servicio a la habitacion?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={CloseRetirarVentaModal}>
                            Cerrar
                        </Button>
                        <Button variant="danger" onClick={FnRetirarVenta}>
                            Retirar
                        </Button>
                    </Modal.Footer>
                    </Modal>
                </>
                {/* ========================== CHECKOUT ========================== */}
                {/* Modal Checkout */}
                <>
                    <Modal show={ShowCheckoutModal} onHide={CloseCheckoutModal} size="xl">
                        <Modal.Header closeButton style={{backgroundColor:'#198754', color:'White'}}>
                            <Modal.Title>Procesar Checkout</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row p-2">
                                {/* COLUMNA 1 */}
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 p-3 pt-0'>
                                    {/* FACTURA */}
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <h5 className="card-title text-success mb-1">Factura</h5>
                                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="row">
                                                <InputComboBoxComprobante value={Idtipocomprobante} campo="Comprobante" name="Idtipocomprobante" obligatorio="*" colsm="12" colmd="12" 
                                                onChange={(IdValue, TextValue) => {setIdtipocomprobante(IdValue);setTxttipocomprobante(TextValue);}}/> {/* <<<<<< CAMBIO */}
                                                <InputComboBoxTipoDocumentoFactura value={Tipodocfactura} campo="Tipo Documento" name="Tipodocfactura" obligatorio="*" colsm="6" colmd="4" onChange={setTipodocfactura}/> {/* <<<<<< CAMBIO */}
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <label htmlFor={`txtRuc`} className="col-3 col-form-label">Num. Documento<span className="text-danger">*</span></label>
                                                        <div className='col-9'>
                                                            <div className="input-group">
                                                                <input value={Rucfactura} id={`txtRuc`} className="form-control" onChange={handleInputRucFacturaChange}/>
                                                                <button className="btn btn-outline-success" onClick={handleSearchRucFactura}><i className="fa-solid fa-rotate fa-lg"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <label htmlFor={`txtRazon`} className="col-3 col-form-label">Cliente<span className="text-danger">*</span></label>
                                                        <div className='col-9'>
                                                            <div className="input-group">
                                                                <input value={Razonfactura} id={`txtRazon`} className="form-control" onChange={handleInputRazonFacturaChange}/>
                                                                <button className="btn btn-outline-success" onClick={handleSearchRazonFactura}><i className="fa-solid fa-search fa-lg"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <label htmlFor={`txtDireccion`} className="col-3 col-form-label">Direccion<span className="text-danger">*</span></label>
                                                        <div className='col-9'>
                                                            <textarea value={Direcfactura} id={`txtDireccion`} className="form-control" rows='1' 
                                                            onChange={(e) => {setDirecfactura(e.target.value);}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {(Idtipocomprobante === '01' || (parseFloat(Monto)+ parseFloat(TotalVenta.toFixed(2))) >= 700) && (
                                                    <>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                            <div className='row'>
                                                                <label htmlFor={`txtRuc`} className="col-3 col-form-label">R.U.C.<span className="text-danger">*</span></label>
                                                                <div className='col-9'>
                                                                    <div className="input-group">
                                                                        <input value={Rucfactura} id={`txtRuc`} className="form-control" onChange={handleInputRucFacturaChange}/>
                                                                        <button className="btn btn-outline-success" onClick={handleSearchRucFactura}><i className="fa-solid fa-rotate fa-lg"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                            <div className='row'>
                                                                <label htmlFor={`txtRazon`} className="col-3 col-form-label">Razon Social<span className="text-danger">*</span></label>
                                                                <div className='col-9'>
                                                                    <div className="input-group">
                                                                        <input value={Razonfactura} id={`txtRazon`} className="form-control" onChange={handleInputRazonFacturaChange}/>
                                                                        <button className="btn btn-outline-success" onClick={handleSearchRazonFactura}><i className="fa-solid fa-search fa-lg"></i></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                            <div className='row'>
                                                                <label htmlFor={`txtDireccion`} className="col-3 col-form-label">Direccion<span className="text-danger">*</span></label>
                                                                <div className='col-9'>
                                                                    <textarea value={Direcfactura} id={`txtDireccion`} className="form-control" rows='1' 
                                                                    onChange={(e) => {setDirecfactura(e.target.value);}}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )} */}
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <label htmlFor={`txtFechavencimiento`} className="col-3 col-form-label">F. Vencimiento<span className="text-danger">*</span></label>
                                                        <div className='col-9'>
                                                            <input value={Fechavencimiento} id={`txtFechavencimiento`} className="form-control" type="date" 
                                                            onChange={(e) => {setFechavencimiento(e.target.value);}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <label htmlFor={`txtNotas`} className="col-3 col-form-label">Notas</label>
                                                        <div className='col-9'>
                                                            <textarea value={Notasfactura || ""} id={`txtNotas`} className="form-control" rows='1' 
                                                            onChange={(e) => {setNotasfactura(e.target.value);}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                {/* COLUMNA 2 */}
                                <div className='col-12 col-sm-12 col-md-12 col-lg-6 p-3 pt-0'>
                                    {/* PAGO */}
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <h5 className="card-title text-success mb-1">Pago</h5>
                                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="row">
                                                <InputComboBoxMedio value={Idmediopago} campo="Medio" name="Idmediopago" obligatorio="*" colsm="12" colmd="12"  
                                                onChange={(IdValue, TextValue) => {setIdmediopago(IdValue);setMediopagofactura(TextValue);}}/> {/* <<<<<< CAMBIO */}
                                                {/* <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                    <div className='row'>
                                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" 
                                                            value="01" onChange={(e) => {setFormapagofactura(e.target.value);}} checked={Formapagofactura === '01'}/>
                                                            <label className="btn btn-outline-success" htmlFor="btnradio1">Al Contado</label>
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off"
                                                            value="02" onChange={(e) => {setFormapagofactura(e.target.value);}} checked={Formapagofactura === '02'}/>
                                                            <label className="btn btn-outline-success" htmlFor="btnradio2">Al Credito</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {Formapagofactura === '02' && (
                                                    <div className={`col-12 col-sm-12 col-md-12 mb-3`}>
                                                            <table className="table table-sm table-striped table-hover table-bordered">
                                                                <thead><tr>
                                                                    <th className='text-center'>#</th><th>Fecha</th><th>Monto</th>
                                                                    <th></th>
                                                                </tr></thead>
                                                                <tbody>
                                                                    {DataCuotasFactura.map((data, index) => (
                                                                        <tr key={index}>
                                                                            <td className='text-center' style={{verticalAlign: 'middle', width:'10%'}}>{index+1}</td>
                                                                            <td style={{width:'40%'}}>
                                                                                <input type='date' className="form-control" name={`in_fechacuota${index + 1}`}
                                                                                        id={`in_fechacuota`} value={InputFechaCuota[index] || ''} onChange={(e) => handleInputFechaCuota(e, index)}/>
                                                                            </td>
                                                                            <td style={{width:'40%'}}>
                                                                                <input type='text' className="form-control" name={`in_montocuota${index + 1}`}
                                                                                        id={`in_montocuota`} value={InputMontoCuota[index] || ''} onChange={(e) => handleInputMontoCuota(e, index)}/>
                                                                            </td>
                                                                            <td className="text-center" style={{verticalAlign: 'middle', width:'10%' }}>
                                                                                <Link onClick={() => FnDeleteCuotaFactura(index,data.id)}>
                                                                                    <i className="fa-solid fa-trash text-danger"></i>
                                                                                </Link>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                    <tr>
                                                                        <td colSpan={3} className='text-center'>
                                                                        </td>
                                                                        <td colSpan={1} className='text-center p-0'>
                                                                            <Link onClick={() => FnAgregarCuotaAdicional()} className='btn btn-success w-100'>
                                                                                <i className="fa-solid fa-plus fa-lg text-white"></i>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                    </div>
                                                )} */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* INCIDENCIAS */}
                                    <div className='row mt-2'>
                                        <div className='col-12'>
                                            <h5 className="card-title text-success mb-1">Incidencias</h5>
                                            <hr style={{borderTop: "2px solid #198754", marginTop:"0px", opacity:"0.6"}} />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-12">
                                            <div className="row">
                                                <InputComboBoxIncidencia value={Txttipoincidencia} campo="Tipo" name="Txttipoincidencia" obligatorio="" colsm="12" colmd="12" 
                                                onChange={(IdValue, TextValue) => {setTxttipoincidencia(TextValue);}}/> {/* <<<<<< CAMBIO */}
                                                <div className={`col-12 col-sm-12 col-md-12 mb-3`}> 
                                                    <div className='row'>
                                                        <label htmlFor={`txtDescripcion`} className="col-3 col-form-label">Descripcion</label>
                                                        <div className='col-9'>
                                                            <textarea value={Descripcionfactura || ""} id={`txtDescripcion`} className="form-control" rows='3' 
                                                            onChange={(e) => {setDescripcionfactura(e.target.value);}}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='col-12' style={{display:'block',justifyContent: 'flex-end'}}>
                            <div className='row mt-1'>
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-1">
                                {EstadoFactura==='PRESENTADO' ?
                                    <Button variant="danger" className='w-100' disabled>Anular</Button>
                                    :
                                    <Button variant="danger" onClick={() => {FnAnularCheckout();}} className='w-100'>Anular</Button>
                                }
                                </div>
                                {Idfactura ? (
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-1">
                                            <Button variant="success" className='w-100' disabled>Generar Comprobante</Button>
                                        </div>
                                    ) : (
                                        <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-1">
                                            <Button variant="success" onClick={() => {FnCheckoutAlquiler();}} className='w-100'>Generar Comprobante</Button>
                                        </div>
                                    )
                                }
                                <div className="col-12 col-sm-4 col-md-4 col-lg-4 p-1">
                                    {EstadoFactura==='PRESENTADO' ?
                                        <Button variant="success" className='w-100' disabled>Cierre Alquiler</Button>
                                    :
                                        loadingCierreAlquiler ? (
                                            <Button variant="success" className='w-100' disabled>Cierre Alquiler</Button>
                                        ) : (
                                            <Button variant="success" onClick={() => {FnCierreAlquiler();}} className='w-100'>Cierre Alquiler</Button>
                                        )
                                    }
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </>
                {/* TOAST */}
                <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className='text-white' 
                    style={{ position: 'fixed', top: '20px',right: '20px', zIndex: 1100, background: `${toastBackground}`,}}>
                    <Toast.Header>
                        <strong className="me-auto">Notificación</strong>
                        <small></small>
                    </Toast.Header>
                    <Toast.Body>{toastMessage}</Toast.Body>
                </Toast>
                <FooterView/>
            </animated.div>
        </>
    );
}

async function UbicarNiveles(id, setters) {
    try {
        const response = await fetch(`${url}/ComboBox/UbigeoReturn/${id}`, {
            method: 'POST',
            credentials: 'include',
        });
        if (response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setIddepartamento2(datos.id3);/* <<<<<< CAMBIO */
                setters.setIdprovincia2(datos.id2);  /* <<<<<< CAMBIO */
                setters.setIddistrito2(datos.id1);   /* <<<<<< CAMBIO */
                return datos;
            }
        }else{
            throw new Error("Error en la respuesta del servidor");
        }
    } catch (error) {
      throw error;
    }
}

// Define la función CustomDropdownToggle utilizando forwardRef
const CustomDropdownToggle = forwardRef((props, ref) => {
    const { children, onClick } = props;
    return (
      <button type="button" ref={ref} onClick={onClick} className="button-dropdown btn btn-transparent" style={{ border: 'solid 1px #198754' }}>
        {children}
      </button>
    );
});
  
// Define el componente MyDropdown que utiliza CustomDropdownToggle
function MyDropdown(props) {
    const { handleShowEditar,handleShowEliminar } = props;
    const customDropdownRef = React.createRef();
    return (
        <Dropdown>
            <Dropdown.Toggle ref={customDropdownRef} as={CustomDropdownToggle} id="dropdown-basic">
                <i className="fas fa-ellipsis-v" style={{color:'#198754'}}></i>
            </Dropdown.Toggle>
            <Dropdown.Menu> {/* style={{ right: '0', left: 'auto' }} */}
                <Dropdown.Item onClick={() => handleShowEditar(props.id)}>
                <i className="fas fa-edit"></i> Editar
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleShowEliminar(props.id)}>
                <i className="fas fa-trash"></i> Eliminar
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}


// Datos del registro
async function UbicarRegistroHuesped(url, id, tabla, token2, setters) {
    try{
        const response = await fetch(`${url}/${tabla}/${tabla}Upd/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${token2}`
            },
            credentials: 'include',
        })
        if(response.ok) {
            const data = await response.json();
            if(data.res){
                const datos = data.datos;
                setters.setIdcliente2(id); /* <<<<<< CAMBIO */
                setters.setClinom2(datos.clinom); /* <<<<<< CAMBIO */
                setters.setCliruc2(datos.cliruc);    /* <<<<<< CAMBIO */
                setters.setClitelf2(datos.clitelf);  /* <<<<<< CAMBIO */
                setters.setClidirec2(datos.clidirec);    /* <<<<<< CAMBIO */
                setters.setCliciudad2(datos.cliciudad);  /* <<<<<< CAMBIO */
                setters.setCliemail2(datos.cliemail);    /* <<<<<< CAMBIO */
                setters.setCliobs2(datos.cliobs);    /* <<<<<< CAMBIO */
                setters.setTxtdepartamento2(datos.txtdepartamento);  /* <<<<<< CAMBIO */
                setters.setTxtprovincia2(datos.txtprovincia);    /* <<<<<< CAMBIO */
                setters.setIddistrito2(datos.iddistrito);    /* <<<<<< CAMBIO */
                setters.setTxtdistrito2(datos.txtdistrito);  /* <<<<<< CAMBIO */
                setters.setTxttipodocumento2(datos.txttipodocumento);    /* <<<<<< CAMBIO */
                setters.setIdpais2(datos.idpais);    /* <<<<<< CAMBIO */
                setters.setTxtpais2(datos.txtpais);  /* <<<<<< CAMBIO */
                setters.setFechaNacimiento2(datos.fechanacimiento);  /* <<<<<< CAMBIO */
                setters.setTxtgenero2(datos.txtgenero);  /* <<<<<< CAMBIO */
                setters.setTxtrangoedad2(datos.txtrangoedad);    /* <<<<<< CAMBIO */
                setters.setTxtestadocivil2(datos.txtestadocivil);    /* <<<<<< CAMBIO */
                setters.setTxtparentesco2(datos.txtparentesco);  /* <<<<<< CAMBIO */
                setters.setEstado2(datos.estado===2 ? 1 : 0);    /* <<<<<< CAMBIO */
                return datos;
            }else{
                throw new Error(data.msg);
            }
        }else{
            throw new Error(`${response.status} ${response.statusText}`);
        }
    }catch (error){
        throw error;
    }
}

export default AlquilerUpd;    /* <<<<<< CAMBIO */

